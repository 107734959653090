<template lang="pug">
	.landing
		.landing__container
			Header(@click="goToLogin")
			Preview(@click="goToLogin")
			Authors
			Create(@click="goToLogin")
			Advantages
			System
			Payments(@click="goToLogin")
			Help(@click="goToLogin")
			Analytics
			Care
			Model
			Steps(@click="goToLogin")
			Calculator
			Support
			Reviews
			Articles(v-if="isLocaleRu")
			Write(v-if="isLocaleRu")
			Info
			Footer

			ModalCookie
</template>

<script>
import { STORAGE } from '@/api/storage';

import Header from '@/components/LandingNew/Header';
import Preview from '@/components/LandingNew/Preview';
import Authors from '@/components/LandingNew/Authors';
import Create from '@/components/LandingNew/Create';
import Advantages from '@/components/LandingNew/Advantages';
import System from '@/components/LandingNew/System';
import Payments from '@/components/LandingNew/Payments';
import Help from '@/components/LandingNew/Help';
import Analytics from '@/components/LandingNew/Analytics';
import Care from '@/components/LandingNew/Care';
import Model from '@/components/LandingNew/Model';
import Steps from '@/components/LandingNew/Steps';
import Calculator from '@/components/LandingNew/Calculator';
import Support from '@/components/LandingNew/Support';
import Reviews from '@/components/LandingNew/Reviews';
import Articles from '@/components/LandingNew/Articles';
import Write from '@/components/LandingNew/Write';
import Info from '@/components/LandingNew/Info';
import Footer from '@/components/LandingNew/Footer';
import ModalCookie from '@/components/Modals/Cookie';

export default {
    name: 'index',
    metaInfo: {
        titleTemplate:
            STORAGE.getLang() == 'ru'
                ? 'Paywall - монетизация Telegram-каналов по подписке.'
                : 'Paywall - Content monetization service',
    },
    components: {
        Header,
        Preview,
        Authors,
        Create,
        Advantages,
        System,
        Payments,
        Help,
        Analytics,
        Care,
        Model,
        Steps,
        Calculator,
        Support,
        Reviews,
        Articles,
        Write,
        Info,
        Footer,
        ModalCookie,
    },
    data: () => ({
        lastPosition: 0,
    }),
    mounted() {
        window.addEventListener('scroll', this.onScroll);

        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), 100);
        }

        this.rememberPageView();
    },
    beforeMount() {
        this.redirectToLoginIfMerchantPath();
    },
    destroyed() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onScroll() {
            const landingHeader = document.querySelector('.header');

            if (this.lastPosition > window.scrollY && window.scrollY > 184) {
                landingHeader.classList.add('header-sticky');
            } else {
                landingHeader.classList.remove('header-sticky');
            }

            this.lastPosition = window.scrollY;
        },

        redirectToLoginIfMerchantPath() {
            if (window.location.href.includes('merchant')) {
                if (this.me && this.me.id) return this.$router.replace({ name: 'projects' });

                return this.goToLogin();
            }
        },
        goToLogin(e) {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            window.location.href = this.loginUrl;
        },
    },
};
</script>

<style lang="scss">
@import url('~@/assets/fonts/gtrestiprodisplay/stylesheet.css');

:root {
    --lc-white: #fff;
    --lc-black: #000;
    --lc-dark: #263f5a;
    --lc-thick: #262626;
    --lc-blue: #4099f7;
    --lc-orange: #ffdb7e;
    --lc-yellow: #ffe9b1;
    --lc-light: #ecf5ff;
    --lc-grey: #eef6ff;
    --lc-grizzle: #eef6fd;
    --lc-azure: #2d5a8a;
    --lc-pale: #dcedff;
    --la-time: 200ms;
}

.overflow {
    overflow: hidden;
}

.is-overflow {
    overflow: hidden;

    &::before {
        position: absolute;
        content: '';
        z-index: 98;
        background-color: rgba(21, 2, 2, 0.5);
        width: 100%;
        min-height: 100%;
        top: -10000vh;
        bottom: -10000vh;
        right: 0;
        left: 0;
    }
}

.landing {
    font-family: 'GT Eesti Pro Display';
    font-style: normal;
    line-height: normal;
    font-weight: 400;
    color: var(--lc-dark);
    background-color: var(--lc-white);

    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1920px;
        padding: 22px 16px 0;
        margin: 0 auto;

        @media (max-width: 1900px) {
            max-width: 1440px;
            padding: 15px 16px 0;
        }

        @media (max-width: 1320px) {
            max-width: 1024px;
        }

        @media (max-width: 767px) {
            padding: 0;
        }
    }

    a {
        text-decoration: none;
    }
}
</style>
