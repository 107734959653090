<template lang="pug">
	.model
		p.model__title
			span {{ $t('landingNew.model.title[0]') }}
			|	{{ $t('landingNew.model.title[1]') }}

		p.model__description(v-html="$t('landingNew.model.description')")

		flickity.model__flickity(:options="flickityOptions")
			.model__item(v-for="(item, i) in list" :key="`model-${i}`")
				.model__icon
					img(:src="`/assets/img/landingNew/model/icon-${i + 1}.svg`")
				p.model__interest(v-html="item.interest")
				p.model__subtitle(v-html="item.subtitle")
				p.model__text(v-html="item.text")

		flickity.model__flickity-mobile(ref="flickity" :options="flickityOptionsMobile")
			.model__slide
				.model__item
					.model__icon
						img(:src="`/assets/img/landingNew/model/icon-1.svg`")
					p.model__interest(v-html="list[0].interest")
					p.model__subtitle(v-html="list[0].subtitle")
					p.model__text(v-html="list[0].text")
				.model__item
					.model__icon
						img(:src="`/assets/img/landingNew/model/icon-2.svg`")
					p.model__interest(v-html="list[1].interest")
					p.model__subtitle(v-html="list[1].subtitle")
					p.model__text(v-html="list[1].text")
			.model__slide
				.model__item
					.model__icon
						img(:src="`/assets/img/landingNew/model/icon-3.svg`")
					p.model__interest(v-html="list[2].interest")
					p.model__subtitle(v-html="list[2].subtitle")
					p.model__text(v-html="list[2].text")
				.model__item
					.model__icon
						img(:src="`/assets/img/landingNew/model/icon-4.svg`")
					p.model__interest(v-html="list[3].interest")
					p.model__subtitle(v-html="list[3].subtitle")
					p.model__text(v-html="list[3].text")
			.model__slide
				.model__item
					.model__icon
						img(:src="`/assets/img/landingNew/model/icon-5.svg`")
					p.model__interest(v-html="list[4].interest")
					p.model__subtitle(v-html="list[4].subtitle")
					p.model__text(v-html="list[4].text")

		.model__buttons
			span.model__button(@click="previous()")
				svg(xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none")
					path(d="M0.292893 6.8593C-0.0976311 7.24982 -0.0976311 7.88299 0.292893 8.27351L6.65685 14.6375C7.04738 15.028 7.68054 15.028 8.07107 14.6375C8.46159 14.2469 8.46159 13.6138 8.07107 13.2233L2.41421 7.56641L8.07107 1.90955C8.46159 1.51903 8.46159 0.885863 8.07107 0.495338C7.68054 0.104814 7.04738 0.104814 6.65685 0.495338L0.292893 6.8593ZM17 6.56641H1V8.56641H17V6.56641Z" fill="#263F5A")

			span.model__button(@click="next()")
				svg(xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none")
					path(d="M16.7071 8.27351C17.0976 7.88299 17.0976 7.24982 16.7071 6.8593L10.3431 0.495338C9.95262 0.104814 9.31946 0.104814 8.92893 0.495338C8.53841 0.885863 8.53841 1.51903 8.92893 1.90955L14.5858 7.56641L8.92893 13.2233C8.53841 13.6138 8.53841 14.2469 8.92893 14.6375C9.31946 15.028 9.95262 15.028 10.3431 14.6375L16.7071 8.27351ZM0 8.56641H16V6.56641H0V8.56641Z" fill="#263F5A")
</template>

<script>
import Flickity from 'vue-flickity';

export default {
    name: 'Model',
    components: {
        Flickity,
    },
    data: () => ({
        flickityOptions: {
            initialIndex: 1,
            prevNextButtons: false,
            pageDots: false,
            autoPlay: 2000,
            wrapAround: true,
            groupCells: 1,
        },
        flickityOptionsMobile: {
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
        },
    }),
    computed: {
        list() {
            return [
                {
                    interest: this.$t('landingNew.model.list[0].interest'),
                    subtitle: this.$t('landingNew.model.list[0].subtitle'),
                    text: this.$t('landingNew.model.list[0].text'),
                },
                {
                    interest: this.$t('landingNew.model.list[1].interest'),
                    subtitle: this.$t('landingNew.model.list[1].subtitle'),
                    text: this.$t('landingNew.model.list[1].text'),
                },
                {
                    interest: this.$t('landingNew.model.list[2].interest'),
                    subtitle: this.$t('landingNew.model.list[2].subtitle'),
                    text: this.$t('landingNew.model.list[2].text'),
                },
                {
                    interest: this.$t('landingNew.model.list[3].interest'),
                    subtitle: this.$t('landingNew.model.list[3].subtitle'),
                    text: this.$t('landingNew.model.list[3].text'),
                },
                {
                    interest: this.$t('landingNew.model.list[4].interest'),
                    subtitle: this.$t('landingNew.model.list[4].subtitle'),
                    text: this.$t('landingNew.model.list[4].text'),
                },
            ];
        },
    },
    methods: {
        next() {
            this.$refs.flickity.next();
        },

        previous() {
            this.$refs.flickity.previous();
        },
    },
};
</script>

<style lang="scss">
.model {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 83px 0 103px;
    background-color: var(--lc-blue);
    border-radius: 80px;
    overflow: hidden;

    @media (max-width: 1900px) {
        padding: 70px 55px 95px;
        border-radius: 40px;
    }

    @media (max-width: 1320px) {
        padding: 63px 55px 70px;
    }

    @media (max-width: 991px) {
        padding: 50px 35px 60px;
    }

    @media (max-width: 767px) {
        align-items: flex-start;
        padding: 30px 15px;
        border-radius: 20px;
    }

    &__title {
        font-size: 83px;
        line-height: 90%;
        /* 74.7px */
        text-align: center;
        color: var(--lc-white);
        margin-bottom: 40px;

        @media (max-width: 1900px) {
            font-size: 55px;
            line-height: normal;
            margin-bottom: 30px;
        }

        @media (max-width: 1230px) {
            font-size: 40px;
            margin-bottom: 20px;
        }

        @media (max-width: 991px) {
            font-size: 36px;
            margin-bottom: 18px;
        }

        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 100%;
            /* 28px */
            text-align: start;
            margin-bottom: 15px;
        }

        span {
            display: inline-flex;
            width: max-content;
            padding: 18px 15px 23px;
            font-size: 83px;
            line-height: 90%;
            /* 74.7px */
            color: var(--lc-blue);
            border-radius: 30px;
            background-color: var(--lc-white);

            @media (max-width: 1900px) {
                padding: 9.5px 30px;
                font-size: 55px;
                border-radius: 30px;
            }

            @media (max-width: 1320px) {
                padding: 2px 10px 7px;
                font-size: 40px;
                border-radius: 15px;
            }

            @media (max-width: 991px) {
                font-size: 36px;
            }

            @media (max-width: 767px) {
                display: block;
                padding: 3px 5px 5px;
                font-size: 28px;
                border-radius: 10px;
            }
        }
    }

    &__description {
        padding-bottom: 6px;
        max-width: 511px;
        font-size: 32px;
        color: var(--lc-white);
        margin: 0 0 100px;
        border-bottom: 3px dashed var(--lc-white);

        @media (max-width: 1900px) {
            padding-bottom: 4px;
            font-size: 28px;
            margin: 0 0 75px;
        }

        @media (max-width: 1320px) {
            font-size: 20px;
            margin: 0 0 50px;
            border-bottom: 2px dashed var(--lc-white);
        }

        @media (max-width: 991px) {
            padding-bottom: 3px;
            font-size: 18px;
            margin: 0 0 40px;
        }

        @media (max-width: 767px) {
            margin: 0 0 25px;
        }
    }

    &__flickity {
        width: 100%;

        .flickity-viewport {
            overflow: visible;
        }

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__item {
        position: relative;
        padding: 45px 42px 40px;
        width: 515px;
        height: 335px;
        border-radius: 30px;
        background-color: rgba(255, 255, 255, 0.17);
        margin-right: 55px;

        @media (max-width: 1900px) {
            display: flex;
            flex-direction: column;
            padding: 30px;
            width: 458px;
            height: 278px;
            border-radius: 30px;
            margin-right: 30px;
        }

        @media (max-width: 1320px) {
            padding: 22px;
            width: 279.256px;
            height: 181.652px;
            border-radius: 16.267px;
            margin-right: 19px;
        }

        @media (max-width: 767px) {
            padding: 15px;
            width: 100%;
            height: 162px;
            border-radius: 20px;
            margin: 0;
        }

        &:nth-child(1) {
            transform: rotate(-0.924deg);

            @media (max-width: 767px) {
                transform: rotate(0);
                margin-bottom: 10px;
            }
        }

        &:nth-child(2) {
            position: relative;
            bottom: -30px;
            transform: rotate(0.65deg);

            @media (max-width: 767px) {
                position: static;
                bottom: 0;
                transform: rotate(0);
            }
        }

        &:nth-child(3) {
            transform: rotate(1.045deg);

            @media (max-width: 767px) {
                transform: rotate(0);
                margin-bottom: 10px;
            }
        }

        &:nth-child(4) {
            bottom: -28px;

            @media (max-width: 767px) {
                position: static;
                bottom: 0;
                transform: rotate(0);
            }
        }

        &:nth-child(5) {
            bottom: -18px;
            transform: rotate(1.995deg);

            @media (max-width: 767px) {
                position: static;
                bottom: 0;
                transform: rotate(0);
                margin-bottom: 10px;
            }
        }
    }

    &__icon {
        position: absolute;
        right: 41px;
        top: -70px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 140px;
        border-radius: 50%;
        background-color: var(--lc-white);

        @media (max-width: 1900px) {
            right: 40px;
            top: -36px;
            width: 115px;
            height: 115px;

            img {
                width: 58%;
                height: 58%;
            }
        }

        @media (max-width: 1320px) {
            right: 20px;
            top: -20px;
            width: 76.5px;
            height: 76.5px;

            img {
                width: 58%;
                height: 58%;
            }
        }

        @media (max-width: 767px) {
            right: 15px;
            top: 15px;
            width: 54px;
            height: 54px;
        }
    }

    &__interest {
        font-size: 83px;
        color: var(--lc-white);
        margin-bottom: 8px;

        span {
            font-size: 48px;
        }

        @media (max-width: 1900px) {
            font-size: 63px;
            margin-bottom: 30px;

            span {
                font-size: 38px;
            }
        }

        @media (max-width: 1320px) {
            font-size: 45.006px;
            margin-bottom: 7.5px;

            span {
                font-size: 26.028px;
            }
        }

        @media (max-width: 767px) {
            font-size: 45.006px;
            margin-bottom: 10.5px;

            span {
                font-size: 26.028px;
            }
        }
    }

    &__subtitle {
        font-size: 28px;
        color: var(--lc-white);
        margin-bottom: 20px;

        @media (max-width: 1900px) {
            font-size: 24px;
            margin-bottom: 30px;
        }

        @media (max-width: 1320px) {
            font-size: 14px;
            margin-bottom: 4px;
        }

        @media (max-width: 767px) {
            font-size: 14px;
            margin-bottom: 4px;
        }
    }

    &__text {
        font-size: 20px;
        font-weight: 350;
        color: var(--lc-white);
        margin-bottom: 0;

        @media (max-width: 1900px) {
            display: flex;
            flex-grow: 1;
            align-items: flex-end;
            font-size: 18px;
            font-weight: 300;
        }

        @media (max-width: 1900px) {
            font-size: 10px;
        }
    }

    &__slide {
        @media (max-width: 767px) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            margin: 0 15px;
        }
    }

    &__flickity-mobile {
        display: none;

        @media (max-width: 767px) {
            display: block;
            width: 100%;

            .flickity-viewport {
                overflow: visible !important;
            }
        }
    }

    &__buttons {
        display: none;

        @media (max-width: 767px) {
            order: 4;
            display: flex;
            margin-top: 30px;
        }
    }

    &__button {
        @media (max-width: 767px) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            background-color: var(--lc-white);

            &:first-child {
                margin-right: 10px;
            }
        }
    }
}
</style>
