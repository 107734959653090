<template lang="pug">
	.calculator
		p.calculator__title {{ $t('landingNew.calculator.title') }}

		.calculator__content
			.calculator__range
				.calculator__range-top
					span(v-if="isLocaleRu" :class="{'active': tab == 1}" @click="tab = 1") ₽
					span(:class="{'active': tab == 2}" @click="tab = 2") $
					span(:class="{'active': tab == 3}" @click="tab = 3") €

				.calculator__range-people
					p.calculator__range-title(v-html="$t('landingNew.calculator.range[0].text')")
					span.calculator__range-text {{ people }}&nbsp;
						| {{ $t('landingNew.calculator.range[0].people') }}

				range-slider.calculator__range-slider(v-model="people" step="500" min="500" max="1000000")

				.calculator__range-price
					p.calculator__range-title(v-html="$t('landingNew.calculator.range[1].text')")
					span.calculator__range-text {{ price }}&nbsp;
						| {{ getCurrency }}

				range-slider.calculator__range-slider(v-model="price" :step="getStepPrice" :min="getPrice" :max="getMaxPrice")



			.calculator__result
				p.calculator__text(v-html="$t('landingNew.calculator.estimation.title')")
				p.calculator__sum
					| {{ $t('landingNew.calculator.estimation.sum[0]') }}
					| {{ sumFrom.toLocaleString(isLocaleRu ? 'ru' : 'eng') }}
					| {{ $t('landingNew.calculator.estimation.sum[1]') }}
					| {{ sumBefore.toLocaleString(isLocaleRu ? 'ru' : 'eng') }}
					| {{ getCurrency }}
					| {{ $t('landingNew.calculator.estimation.sum[2]') }}
				p.calculator__desc(v-html="$t('landingNew.calculator.estimation.desc')")
</template>

<script>
/* https://github.com/ktsn/vue-range-slider */
import RangeSlider from 'vue-range-slider';
import 'vue-range-slider/dist/vue-range-slider.css';

export default {
    name: 'Calculator',
    components: {
        RangeSlider,
    },
    data: () => ({
        tab: 1,
        people: 500,
        price: 0,
        currency: ['руб.', '$', '€'],
    }),
    created() {
        if (!this.isLocaleRu) {
            this.tab = 2;
        }
    },
    computed: {
        getCurrency() {
            if (this.tab == 1) {
                return this.currency[0];
            } else if (this.tab == 2) {
                return this.currency[1];
            } else if (this.tab == 3) {
                return this.currency[2];
            } else {
                return null;
            }
        },
        getPrice() {
            if (this.tab == 1) {
                return (this.price = 100);
            } else if (this.tab == 2) {
                return (this.price = 4);
            } else if (this.tab == 3) {
                return (this.price = 4);
            } else {
                return null;
            }
        },
        getStepPrice() {
            if (this.tab == 1) {
                return 50;
            } else if (this.tab == 2) {
                return 4;
            } else if (this.tab == 3) {
                return 4;
            } else {
                return null;
            }
        },
        getMaxPrice() {
            if (this.tab == 1) {
                return 15000;
            } else if (this.tab == 2) {
                return 600;
            } else if (this.tab == 3) {
                return 600;
            } else {
                return null;
            }
        },
        sumFrom() {
            return (this.people / 100) * 0.5 * this.price * 0.8;
        },
        sumBefore() {
            return (this.people / 100) * 3 * this.price * 0.8;
        },
    },
};
</script>

<style lang="scss">
.calculator {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 112px 40px 100px;
    background-color: var(--lc-blue);
    border-radius: 80px;

    @media (max-width: 1900px) {
        padding: 60px 55px 103px;
        border-radius: 40px;
    }

    @media (max-width: 1320px) {
        padding: 60px 55px;
    }

    @media (max-width: 991px) {
        padding: 50px 25px;
    }

    @media (max-width: 767px) {
        align-items: flex-start;
        padding: 30px 15px;
        border-radius: 20px;
    }

    &__title {
        font-size: 83px;
        line-height: 90%;
        /* 74.7px */
        text-align: center;
        color: var(--lc-white);
        margin-bottom: 89px;

        @media (max-width: 1900px) {
            font-size: 55px;
            line-height: 100px;
            /* 181.818% */
            margin-bottom: 65px;
        }

        @media (max-width: 1320px) {
            font-size: 40px;
            line-height: 90%;
            /* 74.7px */
            margin-bottom: 40px;
        }

        @media (max-width: 991px) {
            font-size: 36px;
            margin-bottom: 32px;
        }

        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 100%;
            /* 28px */
            margin-bottom: 25px;
        }
    }

    &__content {
        display: flex;
        justify-content: center;

        @media (max-width: 767px) {
            flex-direction: column;
            width: 100%;
        }
    }

    &__range {
        position: relative;
        width: 694px;
        height: 467px;
        border-radius: 30px;
        background: var(--lc-white);
        margin-right: 138px;

        &::after {
            content: '';
            position: absolute;
            top: 10px;
            right: -50px;
            width: 100px;
            height: 100px;
            background-image: url('/assets/img/landingNew/calculator/smiley.svg');
            background-size: cover;
        }

        @media (max-width: 1900px) {
            width: 585.516px;
            height: 394px;
            border-radius: 30px;
            margin-right: 107px;

            &::after {
                top: -31px;
                right: -21px;
                width: 72px;
                height: 72px;
            }
        }

        @media (max-width: 1320px) {
            width: 375px;
            height: 253px;
            border-radius: 16.21px;
            margin-right: 75px;

            &::after {
                top: 10px;
                bottom: 12px;
                right: -21px;
                width: 43px;
                height: 43px;
            }
        }

        @media (max-width: 991px) {
            width: 305px;
            height: 203px;
            border-radius: 13px;
            margin-right: 35px;

            &::after {
                bottom: 12px;
                right: -21px;
                width: 43px;
                height: 43px;
            }
        }

        @media (max-width: 767px) {
            width: 100%;
            height: 195px;
            border-radius: 12.536px;
            margin-right: 0;

            &::after {
                top: auto;
                bottom: -12px;
                right: -12px;
                width: 35px;
                height: 35px;
            }
        }
    }

    &__range-top {
        display: flex;
        width: 100%;
        height: 62px;
        background-color: rgba(167, 210, 255, 0.5);
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        overflow: hidden;

        @media (max-width: 1900px) {
            height: 53.5px;
        }

        @media (max-width: 1320px) {
            height: 33.5px;
            border-top-right-radius: 16.21px;
            border-top-left-radius: 16.21px;
        }

        @media (max-width: 991px) {
            height: 30.5px;
        }

        @media (max-width: 767px) {
            height: 26px;
            border-top-right-radius: 12.536px;
            border-top-left-radius: 12.536px;
        }

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            font-size: 28px;
            color: rgba(#4099f7, 0.5);
            border-right: 3px dashed var(--lc-blue);
            transition: var(--la-time);
            cursor: pointer;

            @media (max-width: 1900px) {
                width: 67.5px;
                font-size: 24px;
            }

            @media (max-width: 1320px) {
                width: 43.23px;
                font-size: 14px;
                border-right: 1.621px dashed var(--lc-blue);
            }

            @media (max-width: 991px) {
                width: 33.23px;
                font-size: 13px;
            }

            @media (max-width: 767px) {
                width: 33.5px;
                font-size: 10.827px;
                border-right: 1.254px dashed var(--lc-blue);
            }

            &.active {
                color: var(--lc-blue);
                border-right: 3px dashed transparent;
                background-color: var(--lc-white);

                @media (max-width: 1900px) {
                    border-right: 1.621px dashed transparent;
                }

                @media (max-width: 767px) {
                    border-right: 1.254px dashed transparent;
                }
            }
        }
    }

    &__range-slider {
        width: 100%;
        padding: 0 40px;

        @media (max-width: 1900px) {
            padding: 0 33px;
        }

        @media (max-width: 1320px) {
            padding: 0 22px;
        }

        @media (max-width: 767px) {
            padding: 0 18px;
        }

        .range-slider-rail,
        .range-slider-fill {
            height: 8px;
            border-radius: 6px;
            background-color: #a7d2ff;

            @media (max-width: 1320px) {
                height: 4px;
            }
        }

        .range-slider-knob {
            width: 72px;
            height: 72px;
            border: none;
            box-shadow: none;
            background-color: transparent;
            background-image: url('/assets/img/landingNew/calculator/star.svg');
            background-size: cover;

            @media (max-width: 1900px) {
                width: 52px;
                height: 52px;
            }

            @media (max-width: 1320px) {
                width: 36px;
                height: 36px;
            }

            @media (max-width: 767px) {
                width: 25px;
                height: 25px;
            }
        }
    }

    &__range-people {
        display: flex;
        align-items: center;
        padding: 40px 40px 35px;

        @media (max-width: 1900px) {
            padding: 34px 33px 34px;
        }

        @media (max-width: 1320px) {
            padding: 20px 20px 17.5px;
        }

        @media (max-width: 991px) {
            padding: 14px 14px 11.5px;
        }

        @media (max-width: 767px) {
            padding: 17px 18px 8px;
        }
    }

    &__range-price {
        display: flex;
        align-items: center;
        padding: 40px 40px 35px;

        @media (max-width: 1900px) {
            padding: 30px 33px 30px;
        }

        @media (max-width: 1320px) {
            padding: 15px 20px 17.5px;
        }

        @media (max-width: 991px) {
            padding: 9px 14px 12.5px;
        }

        @media (max-width: 767px) {
            padding: 5px 18px 9px;
        }
    }

    &__range-title {
        max-width: 386px;
        font-size: 32px;
        color: var(--lc-blue);
        margin: 0 auto 0 0;

        @media (max-width: 1900px) {
            max-width: 325px;
            font-size: 26px;
        }

        @media (max-width: 1320px) {
            max-width: 194px;
            font-size: 16px;
        }

        @media (max-width: 991px) {
            max-width: 174px;
            font-size: 13px;
        }

        @media (max-width: 767px) {
            max-width: 144px;
            font-size: 12px;
        }
    }

    &__range-text {
        padding: 22px 25px 19px;
        font-size: 30px;
        color: var(--lc-blue);
        border-radius: 20px;
        background-color: rgba(#a7d2ff, 0.2);

        @media (max-width: 1900px) {
            padding: 17px 21px;
            font-size: 24px;
            border-radius: 20px;
        }
        @media (max-width: 1320px) {
            padding: 11px 15px;
            font-size: 16px;
            border-radius: 10.807px;
        }

        @media (max-width: 991px) {
            padding: 9px 12px;
            font-size: 12px;
            border-radius: 9px;
        }

        @media (max-width: 767px) {
            padding: 9px 13px;
            border-radius: 8.357px;
        }
    }

    &__result {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 664px;
        margin-top: 20px;

        @media (max-width: 1900px) {
            width: 562px;
            margin-top: 30px;
        }

        @media (max-width: 1320px) {
            width: 368px;
            margin-top: 21px;
        }

        @media (max-width: 991px) {
            width: 308px;
            margin-top: 18px;
        }

        @media (max-width: 767px) {
            align-items: flex-start;
            width: 100%;
            margin-top: 21px;
        }
    }

    &__text {
        font-size: 48px;
        color: var(--lc-white);
        text-align: center;
        margin-bottom: 5px;

        @media (max-width: 1900px) {
            font-size: 40px;
            margin-bottom: 16px;
        }

        @media (max-width: 1320px) {
            font-size: 24px;
            margin-bottom: 7px;
        }

        @media (max-width: 991px) {
            font-size: 22px;
            margin-bottom: 5px;
        }

        @media (max-width: 767px) {
            font-size: 18px;
            text-align: start;
        }
    }

    &__sum {
        display: inline-flex;
        width: auto;
        padding: 8px 16px 9px;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--lc-blue);
        border-radius: 22px;
        background: var(--lc-white);
        margin: 0 auto;

        @media (max-width: 1900px) {
            padding: 18px 30px;
            font-size: 40px;
            border-radius: 30px;
        }

        @media (max-width: 1320px) {
            padding: 8px 10px 9px;
            font-size: 24px;
            border-radius: 15px;
        }

        @media (max-width: 991px) {
            font-size: 22px;
        }

        @media (max-width: 767px) {
            padding: 8px 16px;
            font-size: 16px;
            border-radius: 10px;
            margin: 0 auto 0 0;
        }
    }

    &__desc {
        font-size: 26px;
        font-weight: 300;
        color: var(--lc-white);
        text-align: center;
        margin: 44px 0 0;

        @media (max-width: 1900px) {
            font-size: 20px;
            margin: 32px 0 0;
        }

        @media (max-width: 1320px) {
            font-size: 14px;
            margin: 25px 0 0;
        }

        @media (max-width: 991px) {
            font-size: 12px;
            margin: 20px 0 0;
        }

        @media (max-width: 767px) {
            font-size: 14px;
            text-align: start;
            margin: 15px 0 0;
        }
    }
}
</style>
