<template lang="pug">
	.analytics
		span.analytics__title {{ $t('landingNew.analytics.title[0]') }}
		span.analytics__title {{ $t('landingNew.analytics.title[1]') }}
		span.analytics__title(v-html="$t('landingNew.analytics.title[2]')")
		p.analytics__description(v-html="$t('landingNew.analytics.description')")
		img.analytics__image(v-if="isLocaleRu" src="/assets/img/landingNew/analytics/schedule.svg")
		img.analytics__image(v-if="!isLocaleRu" src="/assets/img/landingNew/analytics/scheduleEng.svg")
		.analytics__item-1
			img(src="/assets/img/landingNew/analytics/01.svg")
			p(v-html="$t('landingNew.analytics.list[0]')")
		.analytics__item-2
			img(src="/assets/img/landingNew/analytics/02.svg")
			p(v-html="$t('landingNew.analytics.list[1]')")
		.analytics__item-3
			img(src="/assets/img/landingNew/analytics/03.svg")
			p(v-html="$t('landingNew.analytics.list[2]')")
		.analytics__item-4
			img(src="/assets/img/landingNew/analytics/04.svg")
			p(v-html="$t('landingNew.analytics.list[3]')")
		.analytics__item-5
			img(src="/assets/img/landingNew/analytics/05.svg")
			p(v-html="$t('landingNew.analytics.list[4]')")
		.analytics__item-6
			p(v-html="$t('landingNew.analytics.list[5]')")
			span(v-html="$t('landingNew.analytics.list[6]')")
</template>

<script>
import MyLink from '@/components/LandingNew/UI/MyLink';

export default {
    name: 'Analytics',
    components: {
        MyLink,
    },
};
</script>

<style lang="scss">
.analytics {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 134px 92px 0;
    background-color: var(--lc-blue);
    border-radius: 80px;

    @media (max-width: 1900px) {
        padding: 80px 55px 0;
        border-radius: 40px;
    }

    @media (max-width: 1320px) {
        padding: 58px 45px 0;
    }

    @media (max-width: 991px) {
        padding: 50px 25px 0;
    }

    @media (max-width: 767px) {
        align-items: flex-start;
        padding: 30px 15px;
        border-radius: 20px;
    }

    &__title {
        display: flex;
        width: max-content;
        font-size: 83px;
        font-weight: 400;
        line-height: 90%;
        /* 74.7px */
        color: var(--lc-white);
        text-align: center;
        margin-bottom: 20px;

        @media (max-width: 1900px) {
            font-size: 55px;
            line-height: 63px;
            margin-bottom: 0;
        }

        @media (max-width: 1320px) {
            font-size: 43px;
            line-height: 90%;
            /* 74.7px */
            margin-bottom: 7px;
        }

        @media (max-width: 991px) {
            font-size: 37px;
        }

        @media (max-width: 767px) {
            font-size: 25px;
            line-height: 95%;
            /* 28px */
            text-align: start;
        }

        &--blue {
            padding: 1px 15px 12px;
            color: var(--lc-blue);
            border-radius: 30px;
            background-color: var(--lc-white);
            margin: 0 0 33px;

            @media (max-width: 1900px) {
                padding: 1px 30px 10px;
                border-radius: 30px;
                margin: 0 0 45px;
            }

            @media (max-width: 1320px) {
                padding: 1px 10px 8px;
                border-radius: 15px;
                margin: 0 0 23px;
            }

            @media (max-width: 991px) {
                margin: 0 0 16px;
            }

            @media (max-width: 767px) {
                padding: 2px 5px 6px;
                font-size: 25px;
                line-height: 85%;
                /* 25.2px */
                border-radius: 10px;
                margin: 0 0 8px;
            }
        }
    }

    &__description {
        max-width: 950px;
        font-size: 20px;
        font-weight: 300;
        color: var(--lc-white);
        text-align: center;
        margin: 0 0 84px;

        @media (max-width: 1900px) {
            max-width: 769px;
            font-size: 20px;
            margin: 0 0 45px;
        }

        @media (max-width: 1320px) {
            max-width: 655px;
            font-size: 14px;
            margin: 0 0 30px;
        }

        @media (max-width: 991px) {
            margin: 0 0 25px;
        }

        @media (max-width: 767px) {
            font-size: 14px;
            text-align: start;
            margin: 0 0 20px;
        }
    }

    &__image {
        display: block;
        width: 928px;
        height: 590px;

        @media (max-width: 1900px) {
            width: 888px;
            height: 564px;
        }

        @media (max-width: 1320px) {
            width: 528.611px;
            height: 336px;
        }

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__item-1 {
        position: absolute;
        bottom: 463px;
        left: 119px;
        display: flex;
        align-items: center;
        padding: 0 45px;
        width: 309px;
        height: 133px;
        color: var(--lc-thick);
        border-radius: 50px;
        background: var(--lc-white);
        box-shadow: 0px 0px 97px 0px rgba(255, 255, 255, 0.42) inset;
        transform: rotate(-8.172deg);

        img {
            width: 65px;
            height: 65px;
            transform: rotate(8.172deg);
        }

        p {
            font-size: 24px;
            margin: 0 0 0 20px;
        }

        @media (max-width: 1900px) {
            bottom: 449px;
            left: 20px;
            padding: 22px 26px;
            width: 219px;
            height: auto;
            border-radius: 30px;

            img {
                width: 50px;
                height: 54px;
            }

            p {
                font-size: 19px;
                margin: 0 0 0 15px;
            }
        }

        @media (max-width: 1320px) {
            bottom: 235px;
            left: 68px;
            padding: 18px;
            width: 165px;
            height: 71px;
            border-radius: 26.64px;

            img {
                width: 35px;
                height: 35px;
            }

            p {
                font-size: 14px;
                margin: 0 0 0 10px;
            }
        }

        @media (max-width: 991px) {
            bottom: 235px;
            left: 34px;
            padding: 16px;
            width: 125px;
            height: 61px;
            border-radius: 26.64px;

            img {
                width: 32px;
                height: 32px;
            }

            p {
                font-size: 11px;
                margin: 0 0 0 6px;
            }
        }

        @media (max-width: 767px) {
            position: static;
            padding: 10px 18px;
            width: 100%;
            height: max-content;
            border-radius: 15px;
            box-shadow: 0px 0px 51.68165px 0px rgba(255, 255, 255, 0.42) inset;
            backdrop-filter: blur(7.192807197570801px);
            transform: rotate(0);
            margin-bottom: 10px;

            img {
                width: 32px;
                height: auto;
                transform: rotate(0);
                margin-right: 16px;
            }

            p {
                font-size: 14px;
                margin: 0;
            }
        }
    }

    &__item-2 {
        position: absolute;
        bottom: 293px;
        left: 196px;
        display: flex;
        align-items: center;
        padding: 0 30px;
        width: 309px;
        height: 133px;
        color: var(--lc-thick);
        border-radius: 50px;
        background: var(--lc-white);
        box-shadow: 0px 0px 97px 0px rgba(255, 255, 255, 0.42) inset;
        transform: rotate(4.963deg);

        img {
            width: 59px;
            height: 59px;
            transform: rotate(-4.963deg);
        }

        p {
            font-size: 24px;
            margin: 0 0 0 15px;
        }

        @media (max-width: 1900px) {
            bottom: 319px;
            left: 74px;
            padding: 22px 18px;
            width: 219px;
            height: auto;
            border-radius: 30px;

            img {
                width: 45px;
                height: 45px;
            }

            p {
                font-size: 19px;
                margin: 0 0 0 8px;
            }
        }

        @media (max-width: 1320px) {
            bottom: 145px;
            left: 106px;
            padding: 11px;
            width: 165px;
            height: 71px;
            border-radius: 26.64px;

            img {
                width: 39px;
                height: 39px;
            }

            p {
                font-size: 14px;
                margin: 0 0 0 4px;
            }
        }

        @media (max-width: 991px) {
            bottom: 145px;
            left: 63px;
            padding: 10px;
            width: 145px;
            height: 61px;
            border-radius: 26.64px;

            img {
                width: 35px;
                height: 35px;
            }

            p {
                font-size: 11px;
                margin: 0 0 0 4px;
            }
        }

        @media (max-width: 767px) {
            position: static;
            padding: 10px 18px;
            width: 100%;
            height: max-content;
            border-radius: 15px;
            box-shadow: 0px 0px 51.68165px 0px rgba(255, 255, 255, 0.42) inset;
            backdrop-filter: blur(7.192807197570801px);
            transform: rotate(0);
            margin-bottom: 10px;

            img {
                width: 32px;
                height: auto;
                transform: rotate(0);
                margin-right: 16px;
            }

            p {
                font-size: 14px;
                margin: 0;
            }
        }
    }

    &__item-3 {
        position: absolute;
        bottom: 124px;
        left: 102px;
        display: flex;
        align-items: center;
        padding: 0 34px;
        width: 335px;
        height: 133px;
        color: var(--lc-thick);
        border-radius: 50px;
        background: var(--lc-white);
        box-shadow: 0px 0px 97px 0px rgba(255, 255, 255, 0.42) inset;
        transform: rotate(-6.477deg);

        img {
            width: 59px;
            height: 59px;
            transform: rotate(6.477deg);
        }

        p {
            font-size: 24px;
            margin: 0 0 0 20px;
        }

        @media (max-width: 1900px) {
            bottom: 184px;
            left: 8px;
            padding: 22px 24px;
            width: 237px;
            height: auto;
            border-radius: 30px;

            img {
                width: 60px;
                height: 60px;
            }

            p {
                font-size: 19px;
                margin: 0 0 0 14px;
            }
        }

        @media (max-width: 1320px) {
            bottom: 55px;
            left: 57px;
            padding: 18px;
            width: 178px;
            height: 71px;
            border-radius: 26.64px;

            img {
                width: 33px;
                height: 33px;
            }

            p {
                font-size: 14px;
                margin: 0 0 0 10px;
            }
        }

        @media (max-width: 991px) {
            bottom: 55px;
            left: 26px;
            padding: 18px;
            width: 128px;
            height: 61px;
            border-radius: 26.64px;

            img {
                width: 28px;
                height: 28px;
            }

            p {
                font-size: 11px;
                margin: 0 0 0 6px;
            }
        }

        @media (max-width: 767px) {
            position: static;
            padding: 10px 18px;
            width: 100%;
            height: max-content;
            border-radius: 15px;
            box-shadow: 0px 0px 51.68165px 0px rgba(255, 255, 255, 0.42) inset;
            backdrop-filter: blur(7.192807197570801px);
            transform: rotate(0);
            margin-bottom: 10px;

            img {
                width: 32px;
                height: auto;
                transform: rotate(0);
                margin-right: 16px;
            }

            p {
                font-size: 14px;
                margin: 0;
            }
        }
    }

    &__item-4 {
        position: absolute;
        bottom: 495px;
        right: 204px;
        display: flex;
        align-items: center;
        padding: 0 38px;
        width: 309px;
        height: 133px;
        color: var(--lc-thick);
        border-radius: 50px;
        background: var(--lc-white);
        box-shadow: 0px 0px 97px 0px rgba(255, 255, 255, 0.42) inset;
        transform: rotate(4.21deg);

        img {
            width: 58px;
            height: 58px;
            transform: rotate(-4.21deg);
        }

        p {
            font-size: 24px;
            margin: 0 0 0 22px;
        }

        @media (max-width: 1900px) {
            bottom: 471px;
            right: 66px;
            padding: 22px 24px;
            width: 219px;
            height: auto;
            border-radius: 30px;

            img {
                width: 42px;
                height: 42px;
            }

            p {
                font-size: 19px;
                margin: 0 0 0 14px;
            }
        }

        @media (max-width: 1320px) {
            bottom: 253px;
            right: 85px;
            padding: 18px;
            width: 165px;
            height: 71px;
            border-radius: 26.64px;

            img {
                width: 35px;
                height: 35px;
            }

            p {
                font-size: 14px;
                margin: 0 0 0 10px;
            }
        }

        @media (max-width: 991px) {
            bottom: 253px;
            right: 42px;
            padding: 16px;
            width: 125px;
            height: 61px;
            border-radius: 26.64px;

            img {
                width: 30px;
                height: 30px;
            }

            p {
                font-size: 11px;
                margin: 0 0 0 6px;
            }
        }

        @media (max-width: 767px) {
            position: static;
            padding: 10px 18px;
            width: 100%;
            height: max-content;
            border-radius: 15px;
            box-shadow: 0px 0px 51.68165px 0px rgba(255, 255, 255, 0.42) inset;
            backdrop-filter: blur(7.192807197570801px);
            transform: rotate(0);
            margin-bottom: 10px;

            img {
                width: 32px;
                height: auto;
                transform: rotate(0);
                margin-right: 16px;
            }

            p {
                font-size: 14px;
                margin: 0;
            }
        }
    }

    &__item-5 {
        position: absolute;
        bottom: 302px;
        right: 140px;
        display: flex;
        align-items: center;
        padding: 0 50px 0 38px;
        width: 477px;
        height: 133px;
        color: var(--lc-thick);
        border-radius: 50px;
        background: var(--lc-white);
        box-shadow: 0px 0px 97px 0px rgba(255, 255, 255, 0.42) inset;
        transform: rotate(-5.572deg);

        img {
            width: 58px;
            height: 58px;
            transform: rotate(5.572deg);
        }

        p {
            font-size: 24px;
            margin: 0 0 0 22px;
        }

        @media (max-width: 1900px) {
            bottom: 325px;
            right: 22px;
            padding: 22px 24px;
            width: 338px;
            height: auto;
            border-radius: 30px;

            img {
                width: 38px;
                height: 38px;
            }

            p {
                font-size: 19px;
                margin: 0 0 0 14px;
            }
        }

        @media (max-width: 1320px) {
            bottom: 150px;
            right: 52px;
            padding: 18px;
            width: 254px;
            height: 71px;
            border-radius: 26.64px;

            img {
                width: 29px;
                height: 29px;
            }

            p {
                font-size: 14px;
                margin: 0 0 0 10px;
            }
        }

        @media (max-width: 991px) {
            bottom: 150px;
            right: 26px;
            padding: 16px;
            width: 214px;
            height: 61px;
            border-radius: 26.64px;

            img {
                width: 25px;
                height: 25px;
            }

            p {
                font-size: 11px;
                margin: 0 0 0 6px;
            }
        }

        @media (max-width: 767px) {
            position: static;
            padding: 10px 18px;
            width: 100%;
            height: max-content;
            border-radius: 15px;
            box-shadow: 0px 0px 51.68165px 0px rgba(255, 255, 255, 0.42) inset;
            backdrop-filter: blur(7.192807197570801px);
            transform: rotate(0);
            margin-bottom: 10px;

            img {
                width: 32px;
                height: auto;
                transform: rotate(0);
                margin-right: 16px;
            }

            p {
                font-size: 14px;
                margin: 0;
            }
        }
    }

    &__item-6 {
        position: absolute;
        bottom: 74px;
        right: 129px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 42px;
        width: 486px;
        height: 161px;
        color: var(--lc-thick);
        border-radius: 50px;
        background: var(--lc-white);
        box-shadow: 0px 0px 97px 0px rgba(255, 255, 255, 0.42) inset;
        transform: rotate(3.963deg);

        p {
            font-size: 24px;
            margin-bottom: 0;
        }

        span {
            display: block;
            width: max-content;
            font-size: 24px;
            border-bottom: 1px dashed var(--lc-thick);
        }

        @media (max-width: 1900px) {
            bottom: 153px;
            right: 14px;
            padding: 22px;
            width: 364px;
            height: auto;
            border-radius: 30px;

            p {
                font-size: 19px;
                margin: 0;
            }

            span {
                font-size: 19px;
            }
        }

        @media (max-width: 1320px) {
            bottom: 28px;
            right: 45px;
            padding: 14px;
            width: 265px;
            height: 86px;
            border-radius: 26.64px;

            p {
                font-size: 14px;
                margin: 0;
            }

            span {
                font-size: 14px;
            }
        }

        @media (max-width: 991px) {
            bottom: 36px;
            right: 23px;
            padding: 14px;
            width: 215px;
            height: 66px;
            border-radius: 26.64px;

            p {
                font-size: 11px;
                margin: 0;
            }

            span {
                font-size: 11px;
            }
        }

        @media (max-width: 767px) {
            position: static;
            padding: 10px 18px;
            width: 100%;
            height: max-content;
            border-radius: 15px;
            box-shadow: 0px 0px 51.68165px 0px rgba(255, 255, 255, 0.42) inset;
            backdrop-filter: blur(7.192807197570801px);
            transform: rotate(0);

            p {
                font-size: 14px;
                margin: 0;
            }

            span {
                font-size: 14px;
            }
        }
    }
}
</style>
