<template lang="pug">
	.info
		p.info__title {{ $t('landingNew.info.title') }}

		.info__content
			a.info__link(href="https://docs.paywall.pw/rus/" target="_blank")
				span {{ $t('landingNew.info.link') }}
				svg(xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none")
					rect(width="65" height="65" rx="30" fill="white")
					path(d="M24.1438 42.7561L41.756 25.1439M41.756 25.1439H29.4684M41.756 25.1439V37.4315" stroke="#263F5A" stroke-width="3")
			.info__help
				.info__wrap
					p.info__subtitle {{ $t('landingNew.info.subtitle') }}
					p.info__desc {{ $t('landingNew.info.desc') }}
				a.info__button(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
					img(src="/assets/img/landingNew/info/tg.svg")
					span @paywallsupport
				img.info__smiley-1(src="/assets/img/landingNew/info/smiley-1.svg")
				img.info__smiley-2(src="/assets/img/landingNew/info/smiley-2.svg")
</template>

<script>
export default {
    name: 'Info',
};
</script>

<style lang="scss">
.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 112px 20px 144px;
    background-color: var(--lc-blue);
    border-radius: 80px;
    margin-top: 20px;

    @media (max-width: 1900px) {
        padding: 60px 20px 96px;
    }

    @media (max-width: 1320px) {
        padding: 61px 20px 142px;
        border-radius: 40px;
        margin-top: 15px;
    }

    @media (max-width: 991px) {
        padding: 50px 20px 60px;
    }

    @media (max-width: 767px) {
        align-items: flex-start;
        padding: 30px 15px 49px;
        border-radius: 20px;
    }

    &__title {
        font-size: 83px;
        line-height: 90%;
        /* 74.7px */
        text-align: center;
        color: var(--lc-white);
        margin-bottom: 71px;

        @media (max-width: 1900px) {
            font-size: 55px;
            margin-bottom: 58px;
        }

        @media (max-width: 1320px) {
            font-size: 40px;
            margin-bottom: 48px;
        }

        @media (max-width: 991px) {
            font-size: 36px;
            margin-bottom: 36px;
        }

        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 100%;
            /* 28px */
            text-align: start;
            margin-bottom: 25px;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1180px;

        @media (max-width: 1900px) {
            max-width: 1014px;
        }

        @media (max-width: 1320px) {
            max-width: 662px;
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }

    &__link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 26px 36px 26px 60px;
        width: 100%;
        border-radius: 40px;
        background-color: var(--lc-light);

        @media (max-width: 1900px) {
            padding: 30px;
            border-radius: 30px;
            margin-bottom: 30px;

            svg {
                width: 50px;
                height: 50px;
            }
        }

        @media (max-width: 1320px) {
            padding: 14.5px 20px 14.5px 33.5px;
            border-radius: 15px;
            margin-bottom: 19.5px;

            svg {
                width: 36px;
                height: 36px;
            }
        }

        @media (max-width: 767px) {
            padding: 14px 20px 13px;
            border-radius: 15px;
            margin-bottom: 20px;

            svg {
                width: 33px;
                height: 33px;
            }
        }

        span {
            font-size: 28px;
            font-weight: 500;
            color: var(--lc-blue);
            transition: var(--la-time);

            @media (max-width: 1900px) {
                font-size: 24px;
            }

            @media (max-width: 1320px) {
                font-size: 16px;
            }

            @media (max-width: 767px) {
                font-size: 16px;
            }
        }

        &:hover,
        &:active {
            span {
                opacity: 0.5;
            }
        }
    }

    &__help {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 78px 98px 72px 70px;
        width: 100%;
        border-radius: 50px;
        background-color: var(--lc-light);
        margin-top: 24px;

        @media (max-width: 1900px) {
            align-items: center;
            padding: 50px 30px;
            border-radius: 30px;
            margin-top: 0;
        }

        @media (max-width: 1320px) {
            padding: 40px 40px 36px;
        }

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
            padding: 20px;
            border-radius: 20px;
        }
    }

    &__wrap {
        margin-right: 20px;

        @media (max-width: 1900px) {
            margin-right: 120px;
        }

        @media (max-width: 1320px) {
            margin-right: 45px;
        }

        @media (max-width: 767px) {
            margin-right: 0;
        }
    }

    &__subtitle {
        font-size: 48px;
        line-height: 90%;
        /* 43.2px */
        color: var(--lc-blue);
        margin-bottom: 33px;

        @media (max-width: 1900px) {
            font-size: 36px;
            margin-bottom: 20px;
        }

        @media (max-width: 1320px) {
            font-size: 28px;
        }

        @media (max-width: 767px) {
            font-size: 18px;
            margin-bottom: 15px;
        }
    }

    &__desc {
        max-width: 443px;
        font-size: 20px;
        font-weight: 300;
        color: var(--lc-dark);
        margin-bottom: 0;

        @media (max-width: 1900px) {
            font-size: 20px;
        }

        @media (max-width: 1320px) {
            font-size: 14px;
        }

        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }

    &__button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 26px 74px;
        width: 482px;
        height: max-content;
        border-radius: 30px;
        background-color: var(--lc-blue);
        transition: var(--la-time);

        @media (max-width: 1900px) {
            padding: 30px 50px;
            width: 391px;
            min-width: 391px;
            border-radius: 30px;

            img {
                width: 43px;
                height: 43px;
            }
        }

        @media (max-width: 1320px) {
            padding: 12px 39px 11px 38px;
            width: 238px;
            min-width: 238px;
            border-radius: 15px;

            img {
                width: 37px;
                height: 37px;
            }
        }

        @media (max-width: 767px) {
            padding: 12px 44px 11px;
            width: 100%;
            min-width: 100%;
            justify-content: center;
            height: max-content;

            img {
                width: 33px;
                height: 33px;
                margin-right: 15px;
            }
        }

        span {
            font-size: 28px;
            font-weight: 500;
            color: var(--lc-white);

            @media (max-width: 1900px) {
                font-size: 28px;
            }

            @media (max-width: 1320px) {
                font-size: 14px;
            }
        }

        &:hover,
        &:active {
            opacity: 0.6;
        }
    }

    &__smiley-1 {
        position: absolute;
        bottom: 28px;
        left: -66px;
        width: 92px;
        height: 92px;

        @media (max-width: 1900px) {
            bottom: -48px;
            left: -52px;
        }

        @media (max-width: 1320px) {
            bottom: -8px;
            left: -28px;
            width: 47px;
            height: 47px;
        }

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__smiley-2 {
        position: absolute;
        top: 28px;
        right: -40px;
        width: 84px;
        height: 84px;

        @media (max-width: 1900px) {
            top: 50px;
            right: -54px;
        }

        @media (max-width: 1320px) {
            top: -2px;
            right: -24px;
            width: 55px;
            height: 55px;
        }

        @media (max-width: 767px) {
            display: none;
        }
    }
}
</style>
