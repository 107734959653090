<template lang="pug">
	.advantages(id="advantages")
		p.advantages__title {{ $t('landingNew.advantages.title[0]') }}
			span {{ $t('landingNew.advantages.title[1]') }}
		p.advantages__desc(v-html="$t('landingNew.advantages.desc')")

		.advantages__grid
			.advantages__grid-left
				.advantages__left-top
					.advantages__item.advantages__item--top-left
						span.advantages__circle
						p(v-html="$t('landingNew.advantages.texts[0]')")
					.advantages__item.advantages__item--top-right
						span.advantages__circle
						p(v-html="$t('landingNew.advantages.texts[1]')")

					.advantages__grid-right.advantages__item.advantages__grid-right--mobile
						span.advantages__circle
						p(v-html="$t('landingNew.advantages.texts[2]')")
						.advantages__decor.advantages__decor--first
							img(src="/assets/img/landingNew/advantages/smiles/smiley-1.svg")
							span 108
						.advantages__decor.advantages__decor--second
							img(src="/assets/img/landingNew/advantages/smiles/smiley-2.svg")
							span 169
						.advantages__decor.advantages__decor--third
							img(src="/assets/img/landingNew/advantages/smiles/smiley-3.svg")
							span 59
						.advantages__decor.advantages__decor--fourth
							img(src="/assets/img/landingNew/advantages/smiles/smiley-4.svg")
							span 89
				.advantages__left-bottom
					.advantages__item.advantages__item--bottom-left
						span.advantages__circle
						p(v-html="$t('landingNew.advantages.texts[3]')")
					.advantages__item.advantages__item--bottom-center
						span.advantages__circle
						p(v-html="$t('landingNew.advantages.texts[4]')")
					.advantages__item.advantages__item--bottom-right
						span.advantages__circle
						p(v-html="$t('landingNew.advantages.texts[5]')")
			.advantages__grid-right.advantages__item.advantages__grid-right--desktop
				span.advantages__circle
				p(v-html="$t('landingNew.advantages.texts[2]')")
				.advantages__decor.advantages__decor--first
					img(src="/assets/img/landingNew/advantages/smiles/smiley-1.svg")
					span 108
				.advantages__decor.advantages__decor--second
					img(src="/assets/img/landingNew/advantages/smiles/smiley-2.svg")
					span 169
				.advantages__decor.advantages__decor--third
					img(src="/assets/img/landingNew/advantages/smiles/smiley-3.svg")
					span 59
				.advantages__decor.advantages__decor--fourth
					img(src="/assets/img/landingNew/advantages/smiles/smiley-4.svg")
					span 89

		my-link.advantages__button(:text="$t('landingNew.advantages.button')" :link="'https://t.me/paywall'")
</template>

<script>
import MyLink from '@/components/LandingNew/UI/MyLink';

export default {
    name: 'Advantages',
    components: {
        MyLink,
    },
};
</script>

<style lang="scss">
.advantages {
    display: flex;
    flex-direction: column;
    padding: 120px 124px;
    background-color: var(--lc-white);

    @media (max-width: 1900px) {
        padding: 80px 70px;
    }

    @media (max-width: 1320px) {
        padding: 60px 70px;
    }

    @media (max-width: 991px) {
        padding: 50px 40px;
    }

    @media (max-width: 767px) {
        padding: 30px 15px;
    }

    &__title {
        font-size: 83px;
        line-height: 100px;
        /* 120.482% */
        text-align: center;
        color: var(--lc-blue);
        margin-bottom: 45px;

        @media (max-width: 1900px) {
            font-size: 55px;
            line-height: normal;
            margin-bottom: 45px;
        }

        @media (max-width: 1320px) {
            font-size: 40px;
            margin-bottom: 30px;
        }

        @media (max-width: 991px) {
            font-size: 36px;
            margin-bottom: 20px;
        }

        @media (max-width: 767px) {
            font-size: 28px;
            text-align: start;
            margin-bottom: 15px;
        }

        span {
            padding: 0 15px 6px;
            color: var(--lc-white);
            border-radius: 30px;
            background-color: var(--lc-blue);
            margin-left: 20px;

            @media (max-width: 1900px) {
                display: inline-block;
                padding: 13px 30px 18px;
                border-radius: 30px;
                line-height: 90%;
                /* 36px */
                margin-left: 18px;
            }

            @media (max-width: 1320px) {
                padding: 1px 10px 8px;
                border-radius: 15px;
                margin-left: 10px;
            }

            @media (max-width: 767px) {
                padding: 3px 5px 5px;
                border-radius: 10px;
                margin-left: 5px;
            }
        }
    }

    &__desc {
        max-width: 802px;
        font-size: 20px;
        font-weight: 300;
        text-align: center;
        margin: 0 auto 60px;

        @media (max-width: 1900px) {
            max-width: 724px;
            font-size: 20px;
            margin: 0 auto 45px;
        }

        @media (max-width: 1320px) {
            max-width: 541px;
            font-size: 14px;
            margin: 0 auto 40px;
        }

        @media (max-width: 991px) {
            margin: 0 auto 30px;
        }

        @media (max-width: 767px) {
            font-size: 14px;
            text-align: start;
            margin: 0 0 20px;
        }
    }

    &__grid {
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }

    &__grid-left {
        display: flex;
        flex-direction: column;
        width: calc(66.66% - 15px);
        justify-content: space-between;

        @media (max-width: 1900px) {
            width: calc(66.66% - 12px);
        }

        @media (max-width: 1320px) {
            width: calc(66.66% - 7.5px);
        }

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    &__left-top {
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
            flex-direction: column;
            margin-bottom: 10px;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 33px;
        height: 300px;
        border-radius: 50px;
        background-color: var(--lc-light);
        background-repeat: no-repeat;
        background-position: top right;
        transition: var(--la-time);

        @media (max-width: 1900px) {
            padding: 26px;
            height: 238px;
            border-radius: 50px;
            background-size: 70%;
        }

        @media (max-width: 1320px) {
            padding: 13px;
            height: 162px;
            border-radius: 20px;
            background-size: 80%;
        }

        @media (max-width: 991px) {
            height: 132px;
        }

        @media (max-width: 767px) {
            height: 114px;
            background-size: 70%;
        }

        .advantages__circle {
            width: 95px;
            height: 95px;
            border-radius: 50%;
            background-color: var(--lc-white);
            background-repeat: no-repeat;
            background-position: center;

            @media (max-width: 1900px) {
                width: 75px;
                height: 75px;
                background-size: 50%;
            }

            @media (max-width: 1320px) {
                width: 50.5px;
                height: 50.5px;
                background-size: 50%;
            }

            @media (max-width: 767px) {
                width: 37px;
                height: 37px;
            }
        }

        p {
            font-size: 28px;
            margin-bottom: 0;
            transition: var(--la-time);

            @media (max-width: 1900px) {
                font-size: 22px;
            }

            @media (max-width: 1320px) {
                font-size: 16px;
            }

            @media (max-width: 991px) {
                font-size: 15px;
            }

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }

        &--top-left {
            width: calc(50% - 15px);
            background-image: url('/assets/img/landingNew/advantages/bg-mask/bg-1.svg');

            .advantages__circle {
                background-image: url('/assets/img/landingNew/advantages/icon/icon-1.svg');
            }

            @media (max-width: 1900px) {
                width: calc(50% - 12px);
            }

            @media (max-width: 1320px) {
                width: calc(50% - 7.5px);
            }

            @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 10px;
            }
        }

        &--top-right {
            width: calc(50% - 15px);
            background-image: url('/assets/img/landingNew/advantages/bg-mask/bg-2.svg');

            .advantages__circle {
                background-image: url('/assets/img/landingNew/advantages/icon/icon-2.svg');
            }

            @media (max-width: 1900px) {
                width: calc(50% - 12px);
            }

            @media (max-width: 1320px) {
                width: calc(50% - 7.5px);
            }

            @media (max-width: 767px) {
                width: 100%;
                background-position: top center;
            }
        }

        &--bottom-left {
            width: calc(33.33% - 20px);
            background-image: url('/assets/img/landingNew/advantages/bg-mask/bg-4.svg');

            .advantages__circle {
                background-image: url('/assets/img/landingNew/advantages/icon/icon-4.svg');
            }

            @media (max-width: 1900px) {
                width: calc(33.33% - 16px);
                background-size: 90%;
            }

            @media (max-width: 1320px) {
                width: calc(33.33% - 10px);
            }

            @media (max-width: 767px) {
                width: 100%;
                background-position: top center;
                background-size: 70%;
                margin-bottom: 10px;
            }
        }

        &--bottom-center {
            width: calc(33.33% - 20px);
            background-image: url('/assets/img/landingNew/advantages/bg-mask/bg-5.svg');

            .advantages__circle {
                background-image: url('/assets/img/landingNew/advantages/icon/icon-5.svg');
            }

            @media (max-width: 1900px) {
                width: calc(33.33% - 16px);
                background-size: 90%;
            }

            @media (max-width: 1320px) {
                width: calc(33.33% - 10px);
            }

            @media (max-width: 767px) {
                width: 100%;
                background-position: top left;
                background-size: 70%;
                margin-bottom: 10px;
            }
        }

        &--bottom-right {
            width: calc(33.33% - 20px);
            background-image: url('/assets/img/landingNew/advantages/bg-mask/bg-6.svg');

            .advantages__circle {
                background-image: url('/assets/img/landingNew/advantages/icon/icon-6.svg');
            }

            @media (max-width: 1900px) {
                width: calc(33.33% - 16px);
                background-size: 90%;
            }

            @media (max-width: 1320px) {
                width: calc(33.33% - 10px);
            }

            @media (max-width: 767px) {
                background-position: top center;
                background-size: 70%;
                width: 100%;
            }
        }
    }

    &__left-bottom {
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }

    &__grid-right {
        position: relative;
        width: calc(33.4% - 15px);
        height: 630px;
        background-image: url('/assets/img/landingNew/advantages/bg-mask/bg-3.svg');
        background-position: top right 0;

        .advantages__circle {
            background-image: url('/assets/img/landingNew/advantages/icon/icon-3.svg');
        }

        @media (max-width: 1900px) {
            width: calc(33.4% - 12px);
            height: 500px;
            background-size: 90%;
        }

        @media (max-width: 1320px) {
            width: calc(33.4% - 7.5px);
            height: 340px;
        }

        @media (max-width: 991px) {
            height: 280px;
        }

        &--desktop {
            @media (max-width: 767px) {
                display: none;
            }
        }

        &--mobile {
            display: none;

            @media (max-width: 767px) {
                display: flex;
                width: 100%;
                height: 114px;
                background-position: top right;
                background-size: 70%;
                margin: 11px 0 0;
            }
        }
    }

    &__decor {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: max-content;
        background: var(--lc-white);
        overflow: hidden;

        span {
            font-weight: 500;
            color: var(--lc-blue);
        }

        &--first {
            bottom: 293px;
            right: 61px;
            width: 167.395px;
            height: 83.028px;
            padding: 4px 26px;
            transform: rotate(33.869deg);
            border-radius: 40.175px;
            box-shadow: 0 0 107.13285px 0 var(--lc-white) inset;
            background-color: rgba(255, 255, 255, 0.7);

            span {
                font-size: 33.095px;
            }

            img {
                transform: rotate(-33.869deg);
            }

            @media (max-width: 1900px) {
                bottom: 201px;
                right: 41px;
                padding: 12px 18px;
                width: 120px;
                height: auto;

                span {
                    font-size: 28px;
                }

                img {
                    width: 35px;
                }
            }

            @media (max-width: 1320px) {
                bottom: 157px;
                right: 22px;
                padding: 4px 12px;
                width: 88.302px;
                height: 43.798px;

                span {
                    font-size: 23px;
                }

                img {
                    width: 24px;
                }
            }

            @media (max-width: 991px) {
                bottom: 147px;
                right: 12px;
                padding: 4px 12px;
                width: 70px;
                height: 33.798px;

                span {
                    font-size: 20px;
                }

                img {
                    width: 20px;
                }
            }

            @media (max-width: 767px) {
                top: 14px;
                bottom: auto;
                right: 49px;
                padding: 4px;
                width: 35.24px;
                height: 18.479px;

                span {
                    font-size: 11px;
                }

                img {
                    width: 11px;
                }
            }
        }

        &--second {
            bottom: 300px;
            left: 101px;
            width: 164.355px;
            height: 81.52px;
            padding: 4px 30px 4px 24px;
            transform: rotate(-16.709deg);
            border-radius: 39.445px;
            box-shadow: 0 0 105.18708px 0 var(--lc-white) inset;
            background-color: rgba(255, 255, 255, 0.7);

            span {
                font-size: 34.693px;
            }

            img {
                transform: rotate(17.641deg);
            }

            @media (max-width: 1900px) {
                bottom: 214px;
                left: 72px;
                padding: 10px 14px;
                width: 110px;
                height: auto;

                span {
                    font-size: 28px;
                }

                img {
                    width: 38px;
                }
            }

            @media (max-width: 1320px) {
                bottom: 166px;
                left: 60px;
                padding: 4px 12px;
                width: 86.699px;
                height: 43.003px;

                span {
                    font-size: 23px;
                }

                img {
                    width: 30px;
                }
            }

            @media (max-width: 991px) {
                bottom: 146px;
                left: 48px;
                padding: 4px 12px;
                width: 76.699px;
                height: 36.003px;

                span {
                    font-size: 20px;
                }

                img {
                    width: 26px;
                }
            }

            @media (max-width: 767px) {
                top: 18px;
                bottom: auto;
                left: auto;
                right: 91px;
                padding: 4px;
                width: 37.24px;
                height: 19.479px;

                span {
                    font-size: 11px;
                }

                img {
                    width: 13px;
                }
            }
        }

        &--third {
            bottom: 159px;
            right: 102px;
            width: 162.905px;
            height: 88.597px;
            padding: 4px 22px;
            transform: rotate(28.826deg);
            border-radius: 44.299px;
            box-shadow: 0 0 114.31897 0 var(--lc-white) inset;
            background-color: rgba(255, 255, 255, 0.7);

            span {
                font-size: 40px;
            }

            img {
                transform: rotate(-29.452deg);
            }

            @media (max-width: 1900px) {
                bottom: 110px;
                right: 77px;
                padding: 11px 18px;
                width: 110px;
                height: auto;

                span {
                    font-size: 29px;
                }

                img {
                    width: 36px;
                }
            }

            @media (max-width: 1320px) {
                bottom: 97px;
                right: 47px;
                padding: 4px 12px;
                width: 85.934px;
                height: 46.736px;

                span {
                    font-size: 23px;
                }

                img {
                    width: 28px;
                }
            }

            @media (max-width: 991px) {
                bottom: 92px;
                right: 27px;
                padding: 4px 12px;
                width: 70px;
                height: 36.736px;

                span {
                    font-size: 20px;
                }

                img {
                    width: 23px;
                }
            }

            @media (max-width: 767px) {
                top: 40px;
                bottom: auto;
                right: 53px;
                padding: 4px;
                width: 37.295px;
                height: 20.651px;

                span {
                    font-size: 11px;
                }

                img {
                    width: 13px;
                }
            }
        }

        &--fourth {
            bottom: 159px;
            left: 66px;
            width: 158.437px;
            height: 86.168px;
            padding: 4px 31px 4px 27px;
            transform: rotate(-5.652deg);
            border-radius: 43.084px;
            box-shadow: 0 0 111.18394px 0 var(--lc-white) inset;
            background-color: rgba(255, 255, 255, 0.7);

            span {
                font-size: 38.914px;
            }

            img {
                transform: rotate(5.786deg);
            }

            @media (max-width: 1900px) {
                bottom: 118px;
                left: 48px;
                padding: 12px 18px;
                width: 115px;
                height: auto;

                span {
                    font-size: 27px;
                }

                img {
                    width: 35px;
                }
            }

            @media (max-width: 1320px) {
                bottom: 102px;
                left: 45px;
                padding: 4px 12px;
                width: 83.577px;
                height: 45.454px;

                span {
                    font-size: 23px;
                }

                img {
                    width: 28px;
                }
            }

            @media (max-width: 991px) {
                bottom: 94px;
                left: 35px;
                padding: 4px 12px;
                width: 68px;
                height: 35.454px;

                span {
                    font-size: 20px;
                }

                img {
                    width: 20px;
                }
            }

            @media (max-width: 767px) {
                top: 54px;
                bottom: auto;
                left: auto;
                right: 94px;
                padding: 4px;
                width: 36.354px;
                height: 21.14px;

                span {
                    font-size: 11px;
                }

                img {
                    width: 13px;
                }
            }
        }
    }

    &__button {
        width: 482px;
        margin: 80px auto 0;

        @media (max-width: 1900px) {
            width: 416px;
            margin: 45px auto 0;
        }

        @media (max-width: 1320px) {
            width: 248px;
            margin: 40px auto 0;
        }

        @media (max-width: 767px) {
            width: 100%;
            margin: 30px auto 0;
        }
    }
}
</style>
