<template lang="pug">
	.support
		p.support__title(v-html="$t('landingNew.support.title')")

		.support__list
			.support__item
				img.support__avatar(src="/assets/img/landingNew/support/avatar-1.webp" :alt="$t('landingNew.support.list[0].name')")
				span.support__name {{ $t('landingNew.support.list[0].name') }}
				p.support__career(v-html="$t('landingNew.support.list[0].career')")
			.support__item
				img.support__avatar(src="/assets/img/landingNew/support/avatar-2.webp" :alt="$t('landingNew.support.list[1].name')")
				span.support__name {{ $t('landingNew.support.list[1].name') }}
				p.support__career(v-html="$t('landingNew.support.list[1].career')")
			.support__item
				img.support__avatar(src="/assets/img/landingNew/support/avatar-3.webp" :alt="$t('landingNew.support.list[2].name')")
				span.support__name {{ $t('landingNew.support.list[2].name') }}
				p.support__career(v-html="$t('landingNew.support.list[2].career')")
			//.support__item
				img.support__avatar(src="/assets/img/landingNew/support/avatar-4.webp" :alt="$t('landingNew.support.list[3].name')")
				span.support__name {{ $t('landingNew.support.list[3].name') }}
				p.support__career(v-html="$t('landingNew.support.list[3].career')")
			.support__item
				img.support__avatar(src="/assets/img/landingNew/support/avatar-5.webp" :alt="$t('landingNew.support.list[4].name')")
				span.support__name {{ $t('landingNew.support.list[4].name') }}
				p.support__career(v-html="$t('landingNew.support.list[4].career')")

		.support__grid
			.support__grid-item
				p.support__grid-title {{ $t('landingNew.support.grid[0].title') }}
				p.support__grid-desc {{ $t('landingNew.support.grid[0].desc') }}
				.support__socials(v-if="socials")
					a.support__social-link(v-for="(social, i) in socials" :key="`social-${i}`" :href="social.href" target="_blank")
						img.support__social-image(:src="social.src" :alt="social.name")
			.support__grid-item
				p.support__grid-title {{ $t('landingNew.support.grid[1].title') }}
				p.support__grid-desc {{ $t('landingNew.support.grid[1].desc') }}
				a.support__grid-link(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
					img.support__grid-image(src="/assets/img/landingNew/support/tg.svg")
					span @paywallsupport
			//.support__grid-item
				p.support__grid-title {{ $t('landingNew.support.grid[2].title') }}
				a.support__btn(href="#" target="_blank")
					span.support__btn-text {{ $t('landingNew.support.grid[2].btn') }}
					svg(xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none")
						rect(width="65" height="65" rx="30" fill="white")
						path(d="M24.1438 42.7561L41.756 25.1439M41.756 25.1439H29.4684M41.756 25.1439V37.4315" stroke="#263F5A" stroke-width="3")
</template>

<script>
import { socials } from '@/constants/config';

export default {
    name: 'Support',
    computed: {
        socials() {
            return socials.apply(this);
        },
    },
};
</script>

<style lang="scss">
.support {
    display: flex;
    flex-direction: column;
    padding: 86px 140px 100px;
    background-color: var(--lc-white);

    @media (max-width: 1900px) {
        padding: 60px 70px;
    }

    @media (max-width: 1320px) {
        padding: 80px 70px;
    }

    @media (max-width: 991px) {
        padding: 50px;
    }

    @media (max-width: 767px) {
        padding: 30px 15px;
    }

    &__title {
        font-size: 83px;
        line-height: 90%; /* 74.7px */
        text-align: center;
        color: var(--lc-blue);
        margin-bottom: 80px;

        @media (max-width: 1900px) {
            font-size: 55px;
            margin-bottom: 50px;
        }

        @media (max-width: 1320px) {
            font-size: 40px;
            margin-bottom: 40px;
        }

        @media (max-width: 991px) {
            font-size: 36px;
            margin-bottom: 35px;
        }

        @media (max-width: 767px) {
            font-size: 28px;
            text-align: start;
            margin-bottom: 30px;
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        margin-bottom: 80px;

        @media (max-width: 1900px) {
            margin-bottom: 50px;
        }

        @media (max-width: 1320px) {
            margin-bottom: 38px;
        }

        @media (max-width: 991px) {
            margin-bottom: 30px;
        }

        @media (max-width: 767px) {
            justify-content: flex-start;
            overflow-x: auto;
            //scroll-snap-type: x proximity;
            margin: 0 -15px 30px;

            /* Скрываем scrollbar для IE, Edge и Firefox */
            -ms-overflow-style: none; /* IE и Edge */
            scrollbar-width: none; /* Firefox */

            /* Скрываем scrollbar для Chrome, Safari и Opera */
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;

        @media (max-width: 767px) {
            align-items: flex-start;
            //scroll-snap-align: start;
            margin-right: 15px;

            &:first-child {
                padding-left: 15px;
            }
        }
    }

    &__avatar {
        display: block;
        width: 153px;
        height: 153px;
        border-radius: 50%;
        margin-bottom: 25px;

        @media (max-width: 1900px) {
            width: 140px;
            height: 140px;
            margin-bottom: 31px;
        }

        @media (max-width: 1320px) {
            width: 82.5px;
            height: 82.5px;
            margin-bottom: 12.5px;
        }

        @media (max-width: 991px) {
            width: 70px;
            height: 70px;
            margin-bottom: 10px;
        }

        @media (max-width: 767px) {
            width: 83px;
            height: 83px;
            margin-bottom: 13px;
        }
    }

    &__name {
        font-size: 24px;
        text-align: center;
        margin-bottom: 11px;
        white-space: nowrap;

        @media (max-width: 1900px) {
            font-size: 22px;
            margin-bottom: 13px;
        }

        @media (max-width: 1320px) {
            font-size: 14px;
            margin-bottom: 8px;
        }

        @media (max-width: 991px) {
            font-size: 12px;
        }

        @media (max-width: 767px) {
            font-size: 12px;
            text-align: start;
            margin-bottom: 7px;
        }
    }

    &__career {
        font-size: 20px;
        font-weight: 300;
        text-align: center;

        @media (max-width: 1900px) {
            max-width: 219px;
            font-size: 18px;
        }

        @media (max-width: 1320px) {
            max-width: 100%;
            min-width: 134px;
            font-size: 12px;
        }

        @media (max-width: 991px) {
            min-width: 124px;
            font-size: 11px;
        }

        @media (max-width: 767px) {
            min-width: 113px;
            font-size: 12px;
            text-align: start;
        }
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }

    &__grid-item {
        display: flex;
        flex-direction: column;
        height: 373px;
        border-radius: 50px;
        background-color: var(--lc-light);

        @media (max-width: 1900px) {
            height: 317px;
            border-radius: 30px;
        }

        @media (max-width: 1320px) {
            height: 200px;
            border-radius: 25px;
        }

        @media (max-width: 767px) {
            height: max-content;
            border-radius: 20px;
        }

        &:nth-child(1) {
            padding: 47px 160px 85px 48px;
            width: calc(60% - 15px);
            margin: 0 30px 0 0;

            @media (max-width: 1900px) {
                padding: 50px 32px 50px 46px;
                width: calc(60% - 15px);
                margin: 0 15px 0 0;
            }

            @media (max-width: 1320px) {
                padding: 25px;
                width: calc(60% - 8px);
                margin: 0 15px 0 0;
            }

            @media (max-width: 767px) {
                padding: 15px;
                width: 100%;
                margin: 0 0 10px;
            }
        }

        &:nth-child(2) {
            padding: 47px 160px 85px 42px;
            width: calc(40% - 15px);

            @media (max-width: 1900px) {
                padding: 50px 46px;
                width: calc(40% - 15px);
            }

            @media (max-width: 1320px) {
                padding: 25px;
                width: calc(40% - 8px);
            }

            @media (max-width: 767px) {
                padding: 15px;
                width: 100%;
            }
        }

        &:nth-child(3) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 47px 47px 70px;
            width: 100%;
            height: 320px;

            @media (max-width: 1900px) {
                padding: 25px 25px 30px;
                height: max-content;
            }

            @media (max-width: 767px) {
                padding: 15px;
                height: max-content;
            }
        }
    }

    &__grid-title {
        font-size: 48px;
        line-height: 90%; /* 43.2px */
        color: var(--lc-blue);
        margin-bottom: 28px;

        @media (max-width: 1900px) {
            font-size: 35px;
            margin-bottom: 28px;
        }

        @media (max-width: 1320px) {
            font-size: 24px;
            margin-bottom: 15px;
        }

        @media (max-width: 767px) {
            font-size: 18px;
            margin-bottom: 10px;
        }
    }

    &__grid-desc {
        font-size: 20px;
        font-weight: 300;
        padding-bottom: 70px;

        @media (max-width: 1900px) {
            font-size: 18px;
            padding-bottom: 73px;
        }

        @media (max-width: 1320px) {
            font-size: 12px;
            padding-bottom: 33px;
        }

        @media (max-width: 767px) {
            padding-bottom: 15px;
        }
    }

    &__socials {
        display: flex;
    }

    &__social-link {
        width: 51px;
        min-width: 51px;
        height: 51px;
        min-height: 51px;
        transition: var(--la-time);
        margin-right: 17px;
        border-radius: 50%;

        @media (max-width: 1900px) {
            width: 43px;
            min-width: 43px;
            height: 43px;
            min-height: 43px;
            margin-right: 30px;
        }

        @media (max-width: 1320px) {
            width: 28px;
            min-width: 28px;
            height: 28px;
            min-height: 28px;
            margin-right: 17px;
        }

        @media (max-width: 767px) {
            width: 27px;
            min-width: 27px;
            height: 27px;
            min-height: 27px;
            margin-right: 10px;
        }

        &:hover,
        &:active {
            opacity: 0.5;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__social-image {
        width: 100%;
        height: 100%;
    }

    &__grid-link {
        display: flex;
        align-items: center;
        transition: var(--la-time);

        span {
            font-size: 28px;
            font-weight: 500;
            color: var(--lc-blue);

            @media (max-width: 1320px) {
                font-size: 14px;
            }
        }

        &:hover,
        &:active {
            opacity: 0.5;
        }
    }

    &__grid-image {
        width: 51px;
        min-width: 51px;
        height: 51px;
        min-height: 51px;
        margin-right: 10px;

        @media (max-width: 1900px) {
            width: 43px;
            min-width: 43px;
            height: 43px;
            min-height: 43px;
        }

        @media (max-width: 1320px) {
            width: 28px;
            min-width: 28px;
            height: 28px;
            min-height: 28px;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        width: max-content;
        padding: 26px 30px 26px 50px;
        border-radius: 30px;
        background-color: var(--lc-blue);
        transition: var(--la-time);

        @media (max-width: 1900px) {
            padding: 14px 20px;
            border-radius: 16.19px;
            margin: 5px 0 0;

            svg {
                width: 35px;
                height: 35px;
            }
        }

        @media (max-width: 767px) {
            width: 100%;
            padding: 15px 15px 16px;
            border-radius: 10px;
            margin: 10px 0 0;

            svg {
                display: none;
            }
        }

        &:hover,
        &:active {
            opacity: 0.5;
        }
    }

    &__btn-text {
        font-size: 28px;
        font-weight: 500;
        text-transform: uppercase;
        color: var(--lc-white);
        margin-right: 30px;

        @media (max-width: 1900px) {
            font-size: 14px;
            margin-right: 25px;
        }

        @media (max-width: 767px) {
            font-size: 14px;
            text-align: center;
            margin: 0;
        }
    }
}
</style>
