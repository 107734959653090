<template lang="pug">
	.authors(id="authors")
		p.authors__title
			span Paywall
			|	{{ $t('landingNew.authors.title') }}
		p.authors__desc(v-html="$t('landingNew.authors.desc')")
		p.authors__desc--mobile(v-html="$t('landingNew.authors.desc1')")

		.authors__list
			.authors__item(v-for="(item, i) in 16" :key="`author-${i}`" v-if="i != 5")
				.authors__front
					img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-${i + 1}.png`" :alt="$t(`landingNew.authors.list[${i}].name`)")
					span.authors__name(v-html="$t(`landingNew.authors.list[${i}].name`)")

				a.authors__back(:href="$t(`landingNew.authors.list[${i}].href`)" target="_blank")
					.authors__icon-wrap
						img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-${i + 1}.svg`" :alt="$t(`landingNew.authors.list[${i}].desc`)")
					span.authors__item-desc(v-html="$t(`landingNew.authors.list[${i}].desc`)")

		//flickity.authors__flickity(:options="flickityOptions")
			.authors__item(v-for="(item, i) in 16" :key="`author-${i}`")
				.authors__front
					img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-${i + 1}.png`" :alt="$t(`landingNew.authors.list[${i}].name`)")
					span.authors__name(v-html="$t(`landingNew.authors.list[${i}].name`)")

				a.authors__back(:href="$t(`landingNew.authors.list[${i}].href`)" target="_blank")
					.authors__icon-wrap
						img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-${i + 1}.svg`" :alt="$t(`landingNew.authors.list[${i}].desc`)")
					span.authors__item-desc(v-html="$t(`landingNew.authors.list[${i}].desc`)")

		p.authors__text(v-if="merchants" v-html="merchants")

		flickity.authors__flickity-mobile(ref="flickity" :options="flickityOptionsMobile")
			.authors__slide
				.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-1.png`" :alt="$t(`landingNew.authors.list[0].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[0].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[0].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-1.svg`" :alt="$t(`landingNew.authors.list[0].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[0].desc`)")
				.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-2.png`" :alt="$t(`landingNew.authors.list[1].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[1].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[1].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-2.svg`" :alt="$t(`landingNew.authors.list[1].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[1].desc`)")
				.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-3.png`" :alt="$t(`landingNew.authors.list[2].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[2].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[2].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-3.svg`" :alt="$t(`landingNew.authors.list[2].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[2].desc`)")
				.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-4.png`" :alt="$t(`landingNew.authors.list[3].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[3].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[3].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-4.svg`" :alt="$t(`landingNew.authors.list[3].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[3].desc`)")

			.authors__slide
				.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-5.png`" :alt="$t(`landingNew.authors.list[4].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[4].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[4].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-5.svg`" :alt="$t(`landingNew.authors.list[4].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[4].desc`)")
				.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-16.png`" :alt="$t(`landingNew.authors.list[15].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[15].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[15].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-16.svg`" :alt="$t(`landingNew.authors.list[15].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[15].desc`)")
				//-.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-6.png`" :alt="$t(`landingNew.authors.list[5].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[5].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[5].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-6.svg`" :alt="$t(`landingNew.authors.list[5].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[5].desc`)")
				.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-7.png`" :alt="$t(`landingNew.authors.list[6].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[6].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[6].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-7.svg`" :alt="$t(`landingNew.authors.list[6].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[6].desc`)")
				.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-8.png`" :alt="$t(`landingNew.authors.list[7].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[7].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[7].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-8.svg`" :alt="$t(`landingNew.authors.list[7].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[7].desc`)")

			.authors__slide
				.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-9.png`" :alt="$t(`landingNew.authors.list[8].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[8].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[8].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-9.svg`" :alt="$t(`landingNew.authors.list[8].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[8].desc`)")
				.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-10.png`" :alt="$t(`landingNew.authors.list[9].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[9].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[9].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-10.svg`" :alt="$t(`landingNew.authors.list[9].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[9].desc`)")
				.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-11.png`" :alt="$t(`landingNew.authors.list[10].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[10].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[10].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-11.svg`" :alt="$t(`landingNew.authors.list[10].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[10].desc`)")
				.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-12.png`" :alt="$t(`landingNew.authors.list[11].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[11].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[11].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-12.svg`" :alt="$t(`landingNew.authors.list[11].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[11].desc`)")
			.authors__slide
				.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-13.png`" :alt="$t(`landingNew.authors.list[12].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[12].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[12].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-13.svg`" :alt="$t(`landingNew.authors.list[12].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[12].desc`)")
				.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-14.png`" :alt="$t(`landingNew.authors.list[13].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[13].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[13].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-14.svg`" :alt="$t(`landingNew.authors.list[13].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[13].desc`)")
				.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-15.png`" :alt="$t(`landingNew.authors.list[14].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[14].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[14].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-15.svg`" :alt="$t(`landingNew.authors.list[14].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[14].desc`)")
				//-.authors__item
					.authors__front
						img.authors__avatar(:src="`/assets/img/landingNew/authors/avatars/avatar-16.png`" :alt="$t(`landingNew.authors.list[15].name`)")
						span.authors__name(v-html="$t(`landingNew.authors.list[15].name`)")

					a.authors__back(:href="$t(`landingNew.authors.list[15].href`)" target="_blank")
						.authors__icon-wrap
							img.authors__icon(:src="`/assets/img/landingNew/authors/icon/icon-16.svg`" :alt="$t(`landingNew.authors.list[15].desc`)")
						span.authors__item-desc(v-html="$t(`landingNew.authors.list[15].desc`)")

		.authors__buttons
			span.authors__button(@click="previous()")
				svg(xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none")
					path(d="M0.292893 6.8593C-0.0976311 7.24982 -0.0976311 7.88299 0.292893 8.27351L6.65685 14.6375C7.04738 15.028 7.68054 15.028 8.07107 14.6375C8.46159 14.2469 8.46159 13.6138 8.07107 13.2233L2.41421 7.56641L8.07107 1.90955C8.46159 1.51903 8.46159 0.885863 8.07107 0.495338C7.68054 0.104814 7.04738 0.104814 6.65685 0.495338L0.292893 6.8593ZM17 6.56641H1V8.56641H17V6.56641Z" fill="#263F5A")

			span.authors__button(@click="next()")
				svg(xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none")
					path(d="M16.7071 8.27351C17.0976 7.88299 17.0976 7.24982 16.7071 6.8593L10.3431 0.495338C9.95262 0.104814 9.31946 0.104814 8.92893 0.495338C8.53841 0.885863 8.53841 1.51903 8.92893 1.90955L14.5858 7.56641L8.92893 13.2233C8.53841 13.6138 8.53841 14.2469 8.92893 14.6375C9.31946 15.028 9.95262 15.028 10.3431 14.6375L16.7071 8.27351ZM0 8.56641H16V6.56641H0V8.56641Z" fill="#263F5A")
</template>

<script>
import axios from 'axios';
import { APIURL } from '@/api/index.js';
import { SERVICE_STRING } from '@/utils/string';
import Flickity from 'vue-flickity';

export default {
    name: 'Authors',
    components: {
        Flickity,
    },
    data: () => ({
        merchants: '',
        flickityOptions: {
            initialIndex: 6,
            prevNextButtons: false,
            pageDots: false,
            autoPlay: 2000,
            wrapAround: true,
        },
        flickityOptionsMobile: {
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
        },
    }),
    mounted() {
        this.getMerchants();
    },
    methods: {
        async getMerchants() {
            let text = this.$t('landingNew.authors.text');

            try {
                const responce = await axios.get(`${APIURL}public/merchants_with_payments`);
                this.merchants = text.replace(
                    '%merchants%',
                    responce.data + ' ' + SERVICE_STRING.ru_ends(responce.data, this.$t('landingNew.authors.ends'))
                );
            } catch (error) {
                this.merchants = text.replace('%merchants%', '100500');
            }
        },
        next() {
            this.$refs.flickity.next();
        },

        previous() {
            this.$refs.flickity.previous();
        },
    },
};
</script>

<style lang="scss">
.authors {
    display: flex;
    flex-direction: column;
    padding: 100px 0;
    background-color: var(--lc-white);

    @media (max-width: 1900px) {
        padding: 80px 0;
    }

    @media (max-width: 1320px) {
        padding: 60px 0;
    }

    @media (max-width: 991px) {
        padding: 50px 0;
        overflow-x: hidden;
    }

    @media (max-width: 767px) {
        padding: 30px 15px;
    }

    &__title {
        font-size: 83px;
        text-align: center;
        color: var(--lc-blue);
        margin-bottom: 40px;

        @media (max-width: 1900px) {
            font-size: 55px;
            margin-bottom: 45px;
        }

        @media (max-width: 1320px) {
            font-size: 40px;
            margin-bottom: 30px;
        }

        @media (max-width: 991px) {
            font-size: 36px;
            margin-bottom: 25px;
        }

        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            width: max-content;
            font-size: 28px;
            margin-bottom: 15px;
        }

        span {
            padding: 0 15px 6px;
            color: var(--lc-white);
            border-radius: 30px;
            background-color: var(--lc-blue);

            @media (max-width: 1900px) {
                padding: 8px 30px 10px;
                border-radius: 30px;
            }

            @media (max-width: 1320px) {
                padding: 0 10px 2px;
                border-radius: 15px;
            }

            @media (max-width: 767px) {
                display: flex;
                width: max-content;
                line-height: 90%;
                /* 25.2px */
                padding: 3px 5px 5px;
                border-radius: 10px;
            }
        }
    }

    &__desc {
        max-width: 903px;
        font-size: 20px;
        font-weight: 300;
        text-align: center;
        margin: 0 auto 94px;

        @media (max-width: 1900px) {
            max-width: 903px;
            font-size: 20px;
            margin: 0 auto 74px;
        }

        @media (max-width: 1320px) {
            max-width: 592px;
            font-size: 14px;
            margin: 0 auto 55px;
        }

        @media (max-width: 991px) {
            margin: 0 auto 45px;
        }

        @media (max-width: 767px) {
            display: none;
        }

        &--mobile {
            display: none;

            @media (max-width: 767px) {
                display: block;
                font-size: 14px;
                font-weight: 300;
                text-align: start;
                margin: 0 0 30px;
            }
        }
    }

    &__text {
        font-size: 32px;
        font-weight: 400;
        text-align: center;
        margin: 40px 0 0;

        span {
            font-weight: 500;
            color: var(--lc-blue);
        }

        b {
            font-weight: 400;
            color: var(--lc-dark);
        }

        @media (max-width: 1900px) {
            font-size: 28px;
            margin: 16px 0 0;
        }

        @media (max-width: 1320px) {
            font-size: 20px;
            margin: 24px 0 0;
        }

        @media (max-width: 991px) {
            margin: 16px 0 0;
        }

        @media (max-width: 767px) {
            font-size: 18px;
            text-align: start;
            margin: 0 0 30px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__slide {
        @media (max-width: 767px) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            margin: 0 15px;
        }
    }

    &__item {
        position: relative;
        margin-right: 20px;
        margin-bottom: 42px;

        &:nth-child(6n + 6) {
            margin-right: 0;
        }

        @media (max-width: 1900px) {
            margin-right: 12px;
            margin-bottom: 20px;
        }

        @media (max-width: 1320px) {
            margin-right: 12px;
            margin-bottom: 28px;

            &:nth-child(6n + 6) {
                margin-right: 12px;
            }

            &:nth-child(4n + 4) {
                margin-right: 0;
            }
        }

        @media (max-width: 991px) {
            margin-right: 12px;
            margin-bottom: 16px;

            &:nth-child(6n + 6) {
                margin-right: 12px;
            }

            &:nth-child(4n + 4) {
                margin-right: 12px;
            }

            &:nth-child(3n + 3) {
                margin-right: 0;
            }
        }

        @media (max-width: 767px) {
            width: calc(50% - 5px);
            margin-right: 0;
            margin-bottom: 0;

            &:first-child {
                margin-bottom: 17px;
            }

            &:nth-child(6n + 6) {
                margin-right: 0;
            }

            &:nth-child(4n + 4) {
                margin-right: 0;
            }
        }

        &:hover {
            .authors__front {
                visibility: hidden;
            }

            .authors__back {
                visibility: visible;
            }
        }
    }

    &__front {
        display: flex;
        flex-direction: column;
        width: 278px;
        min-height: 222px;
        cursor: pointer;

        @media (max-width: 1900px) {
            width: 205px;
            min-height: 179px;
        }

        @media (max-width: 1320px) {
            width: 198px;
            min-height: 154px;
        }

        @media (max-width: 991px) {
            width: 188px;
            min-height: 144px;
        }

        @media (max-width: 767px) {
            width: 100%;
            min-height: 110px;
        }
    }

    &__avatar {
        position: relative;
        z-index: 2;
        display: block;
        width: 129px;
        height: 129px;
        border-radius: 50%;
        margin: 0 auto -64px;

        @media (max-width: 1900px) {
            width: 94px;
            height: 94px;
            margin: 0 auto -47px;
        }

        @media (max-width: 1320px) {
            width: 90px;
            height: 90px;
            margin: 0 auto -45px;
        }

        @media (max-width: 991px) {
            width: 80px;
            height: 80px;
            margin: 0 auto -40px;
        }

        @media (max-width: 767px) {
            width: 64px;
            height: 64px;
            margin: 0 auto -32px;
        }
    }

    &__name {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        padding: 85px 4px 39px;
        font-size: 24px;
        font-weight: 500;
        border-radius: 50px;
        border: 2px solid rgba(220, 237, 255, 0.56);
        white-space: nowrap;

        @media (max-width: 1900px) {
            padding: 62px 4px 28px;
            font-size: 16px;
            border-radius: 30px;
        }

        @media (max-width: 1320px) {
            padding: 65px 4px 28px;
            font-size: 14px;
        }

        @media (max-width: 991px) {
            padding: 55px 4px 23px;
        }

        @media (max-width: 767px) {
            padding: 44px 4px 19px;
            font-size: 12px;
            border-radius: 21.5px;
            border: 1.5px solid rgba(220, 237, 255, 0.56);
        }
    }

    &__back {
        position: absolute;
        z-index: 3;
        top: -4px;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 280px;
        min-height: 269px;
        visibility: hidden;

        @media (max-width: 1900px) {
            width: 207px;
            min-height: 213px;
        }

        @media (max-width: 1320px) {
            width: 200px;
            min-height: 188px;
        }

        @media (max-width: 991px) {
            width: 190px;
            min-height: 178px;
        }

        @media (max-width: 767px) {
            transform: rotate(-5deg);
            width: 100%;
            min-height: 134px;
        }
    }

    &__icon-wrap {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 151px;
        height: 151px;
        border-radius: 50%;
        background-color: var(--lc-yellow);
        margin: 0 auto -76px;

        @media (max-width: 1900px) {
            width: 111px;
            height: 111px;
            margin: 0 auto -55.5px;
        }

        @media (max-width: 1320px) {
            width: 105px;
            height: 105px;
            margin: 0 auto -52.5px;
        }

        @media (max-width: 991px) {
            width: 95px;
            height: 95px;
            margin: 0 auto -47.5px;
        }

        @media (max-width: 767px) {
            width: 68px;
            height: 68px;
            margin: 0 auto -34px;
        }
    }

    &__icon {
        width: 50%;
        height: 50%;
    }

    &__item-desc {
        display: flex;
        justify-content: center;
        padding: 90px 4px 30px;
        font-size: 24px;
        font-weight: 500;
        color: var(--lc-dark) !important;
        text-align: center;
        border-radius: 50px;
        background-color: #ffdb7e;

        @media (max-width: 1900px) {
            padding: 68px 4px 24px;
            font-size: 17px;
            border-radius: 42px;
        }

        @media (max-width: 1320px) {
            padding: 64px 4px 26px;
            font-size: 16px;
            border-radius: 35px;
        }

        @media (max-width: 991px) {
            padding: 54px 4px 21px;
        }

        @media (max-width: 767px) {
            padding: 45px 4px 10px;
            font-size: 12px;
            border-radius: 21.5px;
        }
    }

    &__flickity {
        @media (max-width: 767px) {
            display: none;
        }
    }

    &__flickity-mobile {
        display: none;

        @media (max-width: 767px) {
            display: block;
        }
    }

    .flickity-viewport {
        overflow: visible !important;
    }

    &__buttons {
        display: none;

        @media (max-width: 767px) {
            order: 4;
            display: flex;
            margin-top: 30px;
        }
    }

    &__button {
        @media (max-width: 767px) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            background-color: #ebf5ff;

            &:first-child {
                margin-right: 10px;
            }
        }
    }
}
</style>
