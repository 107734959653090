<template lang="pug">
	.system
		span.system__title.system__title--white {{ $t('landingNew.system.title[0]') }}
		span.system__title(v-html="$t('landingNew.system.title[1]')")
		.system__list
			.system__item
				img(src="/assets/img/landingNew/system/icon-1.svg")
				span 01.
				p(v-html="$t('landingNew.system.texts[0]')")
			.system__item
				img(src="/assets/img/landingNew/system/icon-2.svg")
				span 02.
				p(v-html="$t('landingNew.system.texts[1]')")
			.system__item
				img(src="/assets/img/landingNew/system/icon-3.svg")
				span 03.
				p(v-html="$t('landingNew.system.texts[2]')")
			.system__item
				img(src="/assets/img/landingNew/system/icon-4.svg")
				span 04.
				p(v-html="$t('landingNew.system.texts[3]')")
			.system__item
				img(src="/assets/img/landingNew/system/icon-5.svg")
				span 05.
				p(v-html="$t('landingNew.system.texts[4]')")
			.system__item
				img(src="/assets/img/landingNew/system/icon-6.svg")
				span 06.
				p(v-html="$t('landingNew.system.texts[5]')")

		flickity.system__list-mobile(ref="flickity" :options="flickityOptionsMobile")
			.system__slide
				.system__item
					img(src="/assets/img/landingNew/system/icon-1.svg")
					span 01.
					p(v-html="$t('landingNew.system.texts[0]')")
				.system__item
					img(src="/assets/img/landingNew/system/icon-2.svg")
					span 02.
					p(v-html="$t('landingNew.system.texts[1]')")
			.system__slide
				.system__item
					img(src="/assets/img/landingNew/system/icon-3.svg")
					span 03.
					p(v-html="$t('landingNew.system.texts[2]')")
				.system__item
					img(src="/assets/img/landingNew/system/icon-4.svg")
					span 04.
					p(v-html="$t('landingNew.system.texts[3]')")
			.system__slide
				.system__item
					img(src="/assets/img/landingNew/system/icon-5.svg")
					span 05.
					p(v-html="$t('landingNew.system.texts[4]')")
				.system__item
					img(src="/assets/img/landingNew/system/icon-6.svg")
					span 06.
					p(v-html="$t('landingNew.system.texts[5]')")

		.system__buttons
			span.system__button(@click="previous()")
				svg(xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none")
					path(d="M0.292893 6.8593C-0.0976311 7.24982 -0.0976311 7.88299 0.292893 8.27351L6.65685 14.6375C7.04738 15.028 7.68054 15.028 8.07107 14.6375C8.46159 14.2469 8.46159 13.6138 8.07107 13.2233L2.41421 7.56641L8.07107 1.90955C8.46159 1.51903 8.46159 0.885863 8.07107 0.495338C7.68054 0.104814 7.04738 0.104814 6.65685 0.495338L0.292893 6.8593ZM17 6.56641H1V8.56641H17V6.56641Z" fill="#263F5A")

			span.system__button(@click="next()")
				svg(xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none")
					path(d="M16.7071 8.27351C17.0976 7.88299 17.0976 7.24982 16.7071 6.8593L10.3431 0.495338C9.95262 0.104814 9.31946 0.104814 8.92893 0.495338C8.53841 0.885863 8.53841 1.51903 8.92893 1.90955L14.5858 7.56641L8.92893 13.2233C8.53841 13.6138 8.53841 14.2469 8.92893 14.6375C9.31946 15.028 9.95262 15.028 10.3431 14.6375L16.7071 8.27351ZM0 8.56641H16V6.56641H0V8.56641Z" fill="#263F5A")
</template>

<script>
import Flickity from 'vue-flickity';

export default {
    name: 'System',
    components: {
        Flickity,
    },
    data: () => ({
        flickityOptionsMobile: {
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
        },
    }),
    methods: {
        next() {
            this.$refs.flickity.next();
        },

        previous() {
            this.$refs.flickity.previous();
        },
    },
};
</script>

<style lang="scss">
.system {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 128px 128px 120px;
    border-radius: 80px;
    background-color: var(--lc-pale);
    margin-bottom: 20px;

    @media (max-width: 1900px) {
        padding: 60px 55px 70px;
        border-radius: 40px;
    }

    @media (max-width: 1320px) {
        padding: 60px 40px;
        margin-bottom: 15px;
    }

    @media (max-width: 991px) {
        padding: 50px 30px;
        overflow-x: hidden;
    }

    @media (max-width: 767px) {
        align-items: flex-start;
        padding: 30px 15px;
        border-radius: 20px;
    }

    &__title {
        width: max-content;
        font-size: 83px;
        line-height: 90%; /* 74.7px */

        @media (max-width: 1900px) {
            font-size: 55px;
        }

        @media (max-width: 1320px) {
            font-size: 40px;
        }

        @media (max-width: 991px) {
            font-size: 36px;
        }

        @media (max-width: 767px) {
            width: auto;
            font-size: 28px;
            line-height: 100%; /* 28px */
        }

        &--white {
            padding: 9px 15px 10px;
            border-radius: 30px;
            background-color: var(--lc-white);

            @media (max-width: 1900px) {
                padding: 9.5px 30px;
                border-radius: 30px;
                line-height: normal;
                margin-bottom: 18px;
            }

            @media (max-width: 1320px) {
                padding: 2px 10px 7px;
                border-radius: 15px;
                line-height: 90%; /* 25.2px */
                margin-bottom: 0;
            }

            @media (max-width: 767px) {
                padding: 3px 5px 5px;
                border-radius: 10px;
            }
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 75px;

        @media (max-width: 1900px) {
            margin-top: 48px;
        }

        @media (max-width: 1320px) {
            margin-top: 32px;
        }

        @media (max-width: 767px) {
            display: none;
        }
    }

    &__list-mobile {
        display: none;

        @media (max-width: 767px) {
            display: block;
            width: 100%;
            margin-top: 25px;
        }
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px;
        width: calc(33.33% - 26px);
        height: 275px;
        border-radius: 30px;

        @media (max-width: 1900px) {
            padding: 24px;
            width: calc(33.33% - 22px);
            height: 218px;
            border-radius: 30px;
        }

        @media (max-width: 1320px) {
            padding: 15px;
            width: calc(33.33% - 13px);
            height: 155px;
            border-radius: 20px;
        }

        @media (max-width: 991px) {
            padding: 13px;
            height: 130px;
        }

        @media (max-width: 767px) {
            padding: 15px;
            width: 100%;
            height: 155px;
        }

        span {
            position: absolute;
            top: 30px;
            right: 30px;
            font-size: 23.062px;
            opacity: 0.3;

            @media (max-width: 1900px) {
                top: 24px;
                right: 24px;
                font-size: 19px;
            }

            @media (max-width: 1320px) {
                top: 15px;
                right: 15px;
                font-size: 14px;
            }

            @media (max-width: 991px) {
                top: 13px;
                right: 13px;
                font-size: 13px;
            }

            @media (max-width: 767px) {
                top: 15px;
                right: 15px;
                font-size: 14px;
            }
        }

        img {
            width: 80px;

            @media (max-width: 1900px) {
                width: 58px;
            }

            @media (max-width: 1320px) {
                width: 43px;
            }
        }

        p {
            font-size: 26.906px;
            margin-top: 0;

            @media (max-width: 1900px) {
                font-size: 21px;
            }

            @media (max-width: 1320px) {
                font-size: 16px;
            }

            @media (max-width: 991px) {
                font-size: 13px;
                margin-bottom: 0;
            }

            @media (max-width: 767px) {
                font-size: 16px;
                transform: rotate(0);
            }
        }

        &:nth-child(odd) {
            background-color: var(--lc-grey);
        }

        &:nth-child(even) {
            background-color: var(--lc-white);
        }

        &:nth-child(1) {
            transform: rotate(3.881deg);
            margin-bottom: 75px;

            @media (max-width: 1900px) {
                margin-bottom: 52px;
            }

            @media (max-width: 1320px) {
                margin-bottom: 30px;
            }

            @media (max-width: 767px) {
                transform: rotate(0);
                margin-bottom: 11px;
            }
        }

        &:nth-child(2) {
            transform: rotate(-3.046deg);
            margin-bottom: 75px;

            @media (max-width: 1900px) {
                margin-bottom: 52px;
            }

            @media (max-width: 1320px) {
                margin-bottom: 30px;
            }

            @media (max-width: 767px) {
                transform: rotate(0);
                margin-bottom: 0;
            }
        }

        &:nth-child(3) {
            transform: rotate(5.2deg);
            margin-bottom: 75px;

            @media (max-width: 1900px) {
                margin-bottom: 52px;
            }

            @media (max-width: 1320px) {
                margin-bottom: 13px;
            }

            @media (max-width: 767px) {
                transform: rotate(0);
                margin-bottom: 30px;
            }
        }

        &:nth-child(4) {
            transform: rotate(-3.84deg);

            @media (max-width: 767px) {
                transform: rotate(0);
            }
        }

        &:nth-child(5) {
            transform: rotate(1.265deg);

            @media (max-width: 767px) {
                transform: rotate(0);
                margin-bottom: 11px;
            }
        }

        &:nth-child(6) {
            transform: rotate(-2.75deg);

            @media (max-width: 767px) {
                transform: rotate(0);
            }
        }
    }

    &__slide {
        @media (max-width: 767px) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            margin: 0 15px;
        }
    }

    .flickity-viewport {
        overflow: visible !important;
    }

    &__buttons {
        display: none;

        @media (max-width: 767px) {
            order: 4;
            display: flex;
            margin-top: 30px;
        }
    }

    &__button {
        @media (max-width: 767px) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            background-color: var(--lc-white);

            &:first-child {
                margin-right: 10px;
            }
        }
    }
}
</style>
