<template lang="pug">
	.write(id="write")
		p.write__title(v-html="$t('landingNew.write.title')")

		.write__list
			a.write__item(href="https://ria.ru/20230531/blogery-1875221149.html" target="_blank")
				span
			a.write__item(href="https://adindex.ru/news/digital/2023/05/31/312942.phtml" target="_blank")
				span
			a.write__item(href="https://iz.ru/1521223/2023-05-31/rossiiskie-youtube-blogery-nachali-chashche-sozdavat-platnye-telegram-kanaly" target="_blank")
				span
			a.write__item(href="https://www.cossa.ru/trends/324356/" target="_blank")
				span
			a.write__item(href="https://www.gazeta.ru/tech/news/2023/01/27/19585033.shtml " target="_blank")
				span
			a.write__item(href="https://www.kp.ru/online/news/5115264/" target="_blank")
				span
			a.write__item(href="https://news.rambler.ru/internet/50106044-gazeta-ru-sotsialnye-telegram-kanaly-bolshe-vsego-dohoda-prinosyat-vracham/" target="_blank")
				span
			a.write__item(href="https://secretmag.ru/zhizn/rossiyanam-rasskazali-skolko-deneg-prinosyat-vracham-i-uchitelyam-telegram-kanaly-27-01-2023.htm" target="_blank")
				span
</template>

<script>
export default {
    name: 'Write',
};
</script>

<style lang="scss">
.write {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 120px 90px;
    background-color: var(--lc-pale);
    border-radius: 80px;

    @media (max-width: 1900px) {
        padding: 60px 58px;
        border-radius: 40px;
    }

    @media (max-width: 1320px) {
        padding: 60px 55px 44px;
    }

    @media (max-width: 991px) {
        padding: 50px 45px 34px;
    }

    @media (max-width: 767px) {
        align-items: flex-start;
        padding: 30px 15px 20px;
        border-radius: 20px;
    }

    &__title {
        font-size: 83px;
        line-height: 90%; /* 74.7px */
        text-align: center;
        color: var(--lc-blue);
        margin-bottom: 96px;

        @media (max-width: 1900px) {
            font-size: 55px;
            margin-bottom: 45px;
        }

        @media (max-width: 1320px) {
            font-size: 40px;
            margin-bottom: 30px;
        }

        @media (max-width: 991px) {
            font-size: 36px;
            margin-bottom: 25px;
        }

        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 100%; /* 28px */
            text-align: start;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @media (max-width: 767px) {
            justify-content: space-between;
        }
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(25% - 22.5px);
        height: 126px;
        border-radius: 40px;
        background-color: var(--lc-white);
        margin: 0 30px 30px 0;

        span {
            width: 100%;
            height: 100%;
            background-color: #a6a6a6;
            mask-repeat: no-repeat;
            mask-size: 50%;
            mask-position: center;
        }

        &:hover,
        &:active {
            span {
                background-color: var(--lc-blue);
                transition: var(--la-time);
            }
        }

        &:nth-child(1) {
            span {
                mask-image: url('/assets/img/landingNew/write/icon-1.svg');
                mask-size: 80%;
            }
        }

        &:nth-child(2) {
            span {
                mask-image: url('/assets/img/landingNew/write/icon-2.svg');
                mask-size: 60%;
            }
        }

        &:nth-child(3) {
            span {
                mask-image: url('/assets/img/landingNew/write/icon-3.svg');
            }
        }
        &:nth-child(4) {
            span {
                mask-image: url('/assets/img/landingNew/write/icon-4.svg');
            }
        }

        &:nth-child(5) {
            span {
                mask-image: url('/assets/img/landingNew/write/icon-5.svg');
                mask-size: 65%;
            }
        }

        &:nth-child(6) {
            span {
                mask-image: url('/assets/img/landingNew/write/icon-6.svg');
            }
        }

        &:nth-child(7) {
            span {
                mask-image: url('/assets/img/landingNew/write/icon-7.svg');
                mask-size: 70%;
            }
        }

        &:nth-child(8) {
            span {
                mask-image: url('/assets/img/landingNew/write/icon-8.svg');
            }
        }

        @media (max-width: 1900px) {
            width: calc(25% - 23.5px);
            height: 100px;
            border-radius: 30px;
            margin: 0 23px 23px 0;
        }

        @media (max-width: 1320px) {
            width: calc(25% - 12px);
            height: 68px;
            border-radius: 20px;
            margin: 0 16px 16px 0;
        }

        @media (max-width: 767px) {
            width: calc(50% - 5px);
            height: 45.438px;
            border-radius: 13.364px;
            margin: 0 0 10px;

            span {
                mask-size: 20%;
            }

            &:nth-child(1) {
                span {
                    mask-size: 60%;
                }
            }

            &:nth-child(2) {
                span {
                    mask-size: 40%;
                }
            }

            &:nth-child(5) {
                span {
                    mask-size: 45%;
                }
            }

            &:nth-child(7) {
                span {
                    mask-size: 50%;
                }
            }
        }

        @media (max-width: 450px) {
            span {
                mask-size: 40%;
            }

            &:nth-child(1) {
                span {
                    mask-size: 80%;
                }
            }

            &:nth-child(2) {
                span {
                    mask-size: 60%;
                }
            }

            &:nth-child(5) {
                span {
                    mask-size: 65%;
                }
            }

            &:nth-child(7) {
                span {
                    mask-size: 70%;
                }
            }
        }

        &:nth-child(4n + 4) {
            margin-right: 0;

            @media (max-width: 767px) {
                margin: 0 0 10px;
            }
        }
    }
}
</style>
